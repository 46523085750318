<div class="card">
    <p-toolbar styleClass="mb-1 gap-1 bg-white border-0" class="crudtable-addBtn">
        <ng-template pTemplate="left">
            <p-button label="Nuevo Registro" [rounded]="true" (click)="addItem()"></p-button>
        </ng-template>
        <!-- <ng-template pTemplate="right">
            <p-button icon="pi pi-file-export" [rounded]="true" severity="primary" [outlined]="true"></p-button>
        </ng-template> -->
    </p-toolbar>
    <p-table
        #dt
        [value]="crudTable.data"
        [rows]="crudTable.data.length"
        [rowsPerPageOptions]="[crudTable.data.length,5,10,20]"
        [paginator]="true"
        [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"

        responsiveLayout="stack" [breakpoint]="'960px'"
        [rowHover]="true"
        [dataKey]="crudTable.dataKey"
        currentPageReportTemplate="Registros del {first} al {last} de {totalRecords} registros"
        [showCurrentPageReport]="true"
    >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let header of crudTable.headers" [pSortableColumn]="header.sortItem" class="table__header-title">
                    {{header.label}} <p-sortIcon *ngIf="header.sorted" [field]="header.sortItem"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-columns="columns">
            <tr>
                <td *ngFor="let itemId of crudTable.items; index as i"> <span class="p-column-title font-bold">{{crudTable.headers[i].label}}</span> {{ item[itemId] === true ? 'Si' : item[itemId] === false ? 'No' :  item[itemId] }}</td>
                <td>
                    <span class="p-column-title font-bold">Acciones</span>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary m-1" (click)="initEdit(item)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger m-1" (click)="initDelete(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">Total de registros {{ crudTable.data ? crudTable.data.length : 0 }}.</div>
        </ng-template>
    </p-table>
</div>
