<app-page-header
  [title]="'Grupos'"
  [subtitle]="'Módulo de administración de grupos'"
></app-page-header>
<div class="surface-section px-4 py-2 md:px-6 lg:px-8 text-center">
  <div class="font-bold text-md">
    <span class="text-primary-600">Gestionar grupos del ciclo</span>
  </div>
</div>
<div class="card flex justify-content-center">
  <p-dropdown
    [options]="seasons"
    [(ngModel)]="seasonSelected"
    placeholder="Seleccione un ciclo"
    optionLabel="name"
    (ngModelChange)="onSeasonSelected($event)"
  ></p-dropdown>
</div>
<div class="card" *ngIf="showTable">
  <p-toolbar styleClass="mb-1 gap-1 bg-white border-0">
    <ng-template pTemplate="left">
      <p-button
        label="Nuevo Registro"
        [rounded]="true"
        (click)="addItem()"
      ></p-button>
    </ng-template>
    <ng-template pTemplate="right">
      <p-button
        label="Clonar Grupos Recurrentes"
        [text]="true"
        [rounded]="true"
        [raised]="true"
        severity="primary"
        (click)="selectRecurrentOrigin()"
      ></p-button>
    </ng-template>
  </p-toolbar>
  <p-table
    #dt
    [value]="crudTableData.data"
    [rows]="crudTableData.data.length"
    [rowsPerPageOptions]="[crudTableData.data.length,5,10,20]"
    [paginator]="true"
    [globalFilterFields]="[
      'classGroupId',
      'headquarter.name',
      'course.name',
      'level.name',
      'sch.days',
      'season.name',
      'maxMembers',
      'state.membersCount',
      'isRecurrent',
      'state.isActive',
      'instructor.name'
    ]"
    [tableStyle]="{ 'min-width': '70rem' }"
    [breakpoint]="'720px'"
    responsiveLayout="stack"
    [rowHover]="true"
    [dataKey]="crudTableData.dataKey"
    currentPageReportTemplate="Registros del {first} al {last} de {totalRecords} registros"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        (click)="exportExcel()"
        class="p-button-primary mr-2"
        pTooltip="Exportar Excel"
        tooltipPosition="bottom"
      ></button>

      <button
        pButton
        label="Limpiar Filtros"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(dt)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="dt.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Búsqueda por palabras"
        />
      </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let header of crudTableData.headers"
          class="table__header-title"
          style="min-width: 1.5rem"
        >
          {{ header.label }}
          <p-columnFilter
            type="text"
            *ngIf="header.sorted"
            [field]="header.sortItem"
            display="menu"
            [showMatchModes]="false"
            matchMode="contains"
            [hideOnClear]="true"
          ></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td style="max-width: 12rem;">{{ item["classGroupId"] }}</td>
        <td>{{ item["headquarter"]["name"] }}</td>
        <td>{{ item["course"]["name"] }}</td>
        <td>{{ item["level"]["name"] }}</td>
        <td>
          {{ item["sch"]["days"] }}
        </td>
        <td>{{ item["season"]["name"] }}</td>
        <td>{{ item["maxMembers"] }}</td>
        <td>{{ item["state"]["membersCount"] }}</td>
        <td>{{ item["isRecurrent"] ? "Si" : "No" }}</td>
        <td>{{ item["state"]["isActive"] ? "Si" : "No" }}</td>
        <td>
          {{ item["instructor"] ? item["instructor"]["name"] : "Sin Asignar" }}
        </td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded p-button-primary m-1"
            (click)="editItem(item)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger m-1"
            (click)="deleteItem(item)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        Total de registros
        {{ crudTableData.data ? crudTableData.data.length : 0 }}.
      </div>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <label for="address">Ciclo</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="season"
          [options]="seasons"
          optionLabel="name"
          placeholder="Seleccione un ciclo"
          [id]="'id'"
        ></p-dropdown>
        <small id="address-small">Seleccione un ciclo.</small>

        <label for="name">Sede</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="headquarter"
          [options]="headqueters"
          optionLabel="name"
          placeholder="Seleccione una sede"
        ></p-dropdown>
        <small id="name-small">Seleccione una sede.</small>

        <label for="name">Curso</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="course"
          [options]="courses"
          optionLabel="name"
          placeholder="Seleccione un curso"
          (onChange)="getAvailableLevels()"
        ></p-dropdown>
        <small id="name-small">Seleccione un curso.</small>

        <label for="address">Nivel</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="level"
          [options]="validateForm.get('course')?.value?.levels || levels"
          optionDisabled="disableToRegister"
          optionLabel="name"
          placeholder="Select un nivel"
        ></p-dropdown>
        <small id="address-small">Seleccione un nivel.</small>

        <label for="address">Horario</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="sch"
          [options]="schs"
          optionLabel="days"
          placeholder="Select un horario"
        ></p-dropdown>
        <small id="address-small">Seleccione un horario.</small>

        <label for="address">Max. Capacidad Inscritos</label>
        <p-inputNumber
          inputId="integeronly"
          formControlName="maxMembers"
          [useGrouping]="false"
        ></p-inputNumber>

        <label for="address">Número de clases</label>
        <p-inputNumber
          inputId="integeronly"
          formControlName="sessionsNumber"
          [useGrouping]="false"
        ></p-inputNumber>

        <label for="isActive">Recurrencia</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="isRecurrent"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
        <small id="address-small"
          >Indique si el grupo se repite cada ciclo.</small
        >

        <label for="address">Instructor</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="instructor"
          [options]="instructors"
          optionLabel="name"
          placeholder="Select un instructor"
        ></p-dropdown>
        <small id="address-small">Asigne un instructor.</small>

        <label for="isActive">Estado</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="isActive"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
        <small id="address-small">indique el estado del grupo.</small>
      </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="showForm = false"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>

<div *ngIf="seasonSelected">
  <p-dialog
    header="Seleccione Ciclo de Origen"
    [(visible)]="recurrentSelectionModalVisible"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '50vw' }"
  >
    <p>
      Seleccione el ciclo desde el cual desea clonar los grupos marcados como
      recurrentes para ser creados en el ciclo <b>{{ seasonSelected.name }}</b>
    </p>
    <div class="flex py-2 justify-content-center">
      <p-dropdown
        appendTo="body"
        [options]="seasonsToClone"
        [(ngModel)]="recurrentSeasonSelected"
        placeholder="Seleccione un ciclo de origen"
        optionLabel="name"
      ></p-dropdown>
    </div>

    <div class="flex justify-content-center">
      <p-button
        appendTo="body"
        label="Clonar"
        [rounded]="true"
        severity="primary"
        (click)="createReccurentGroups()"
        [disabled]="!recurrentSeasonSelected"
      ></p-button>
    </div>
  </p-dialog>
</div>
<p-toast></p-toast>
