import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { CrudTable } from '../../../model/CrudTable';
import { HttpResponse } from '../../../model/HttpResponse';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { GroupsSeasonsComponent } from '../../gestor/ciclos/groups-seasons/groups-seasons.component';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
import { InstructorSeasonsGroupsComponent } from "../instructor-seasons-groups/instructor-seasons-groups.component";

@Component({
    selector: 'app-instructor-seasons',
    standalone: true,
    providers: [MessageService, ConfirmationService],
    templateUrl: './instructor-seasons.component.html',
    styleUrl: './instructor-seasons.component.scss',
    imports: [
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        ReactiveFormsModule,
        DialogModule,
        InputTextModule,
        CommonModule,
        InputSwitchModule,
        SelectButtonModule,
        TableModule,
        MultiSelectModule,
        FormsModule,
        GroupsSeasonsComponent,
        InstructorSeasonsGroupsComponent
    ]
})
export class InstructorSeasonsComponent implements OnInit{
  seasons: Season[] = [];
  seasonSelected!: Season;
  loading: boolean = false;
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  showSeasons: boolean = false;
  showSeasonGroups: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private gestionService: GestionService,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.selectSeasons();
  }

  async selectSeasons() {
    this.loadingService.showLoading();
    this.loading = true;
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'availableToAdministrativeGestion',
              parameterName: '@Id',
              parameterValue: true,
            }
          ],
        },
      },
      type: AcademyAdministratorType.SEASONS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if (res.response.length > 0) {
          this.seasons = res.response;
          this.showSeasons = true;
        }
        this.loading = false;
        this.loadingService.hideLoading();
      });
  }

  showGroups(season: Season) {
    this.seasonSelected = season;
    this.showSeasons = false;
    this.showSeasonGroups = true;
  }

  returnAllSeasons() {
    this.showSeasons = true;
    this.showSeasonGroups = false;
  }

  closeDialog() {
    this.showForm = false;
  }
  clear(table: Table) {
    table.clear();
  }
}
