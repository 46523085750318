import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { PaymentMetod } from '../../../../model/AdministrationData';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership } from '../../../../model/Membership';
import { Season } from '../../../../model/Season';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../../services/administration.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
@Component({
  selector: 'app-memberships-report',
  standalone: true,
  imports: [
    MenubarModule,
    CalendarModule,
    CommonModule,
    CardModule,
    ChartModule,
    DropdownModule,
    FormsModule
  ],
  templateUrl: './memberships-report.component.html',
  styleUrl: './memberships-report.component.scss',
})
export class MembershipsReportComponent implements OnInit {
  items: MenuItem[] | undefined;
  membershipReportType: string = 'daily';
  daySelected: string = moment().format('YYYY-MM-DD 00:00:00');
  memberships: Membership[] = [];
  seasons:Season[] = [];
  seasonSelected!:Season | undefined;
  data: any;
  options: any;
  defaultStatisticsData: any = {
    totalMemberships: 0,
    totalAmount: 0,
    countByType: {
      newMemberships: 0,
      renewals: 0,
    },
    countByOrigin: {
      fromWeb: 0,
      fromPresential: 0,
    },
  };
  statisticsData: any = {
    totalMemberships: 0,
    totalAmount: 0,
    countByType: {
      newMemberships: 0,
      renewals: 0,
    },
    countByOrigin: {
      fromWeb: 0,
      fromPresential: 0,
    },
  };
  constructor(
    private readonly gestionService: GestionService,
    private readonly loadingService: LoadingService,
    private readonly administrationService: AdministrationService
  ) {}
  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    this.data = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [540, 325, 702],
          backgroundColor: [
            documentStyle.getPropertyValue('--blue-500'),
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--green-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--blue-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--green-400'),
          ],
        },
      ],
    };
    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor,
          },
        },
      },
    };
    this.items = [
      {
        label: 'Fecha',
        icon: 'pi pi-calendar',

        command: () => {
          this.changeReportType('daily');
        },
      },
      {
        label: 'Ciclos',
        icon: 'pi pi-calendar-plus',
        command: () => {
          this.changeReportType('seasons');
        },
      },
    ];
  }

  changeReportType(type: string) {
    this.membershipReportType = type;
    this.statisticsData = this.defaultStatisticsData;
    this.seasonSelected = undefined;
    switch (type) {
      case 'daily':
        this.daySelected = moment().format('YYYY-MM-DD');
        break;
      case 'seasons':
        this.selectSeasons();
        break;
      default:
        break;
    }
  }

  async onChangeDay(event: any) {
    this.memberships = [];
    this.daySelected = moment(event).format('YYYY-MM-DD');
    await this.getMemberships();
  }

  async getMemberships() {
    this.loadingService.showLoading();

    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '>=',
              evaluatedProperty: 'auditData.created_at',
              parameterName: '@Option1',
              parameterValue: this.daySelected.concat(' 00:00:00'),
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '<=',
              evaluatedProperty: 'auditData.created_at',
              parameterName: '@Option2',
              parameterValue: this.daySelected.concat(' 23:59:59'),
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response.length > 0) {
        this.memberships = res.response;
      }
      this.getDailyStatistics();
      this.loadingService.hideLoading();
    });
  }

  getDailyStatistics() {
    this.statisticsData = {
      totalMemberships: this.memberships.length,
      totalAmount: this.calculateAmount(this.memberships),
      countByType: this.countByType(),
      countByOrigin: this.countByOrigin(),
    };
  }

  calculateAmount(memberships: Membership[]) {
    let totalAmount = 0;
    memberships.forEach((membership) => {
      totalAmount +=
        membership.administrativeData.paymentInfo?.paymentData.amount ?? 0;
    });
    return totalAmount;
  }

  countByType(): any {
    let newMemberships = 0;
    let renewals = 0;
    this.memberships.forEach((membership) => {
      if (
        membership.administrativeData.paymentInfo?.productsAndServices.some(
          (product) => product.name === 'AFILIACIÓN'
        )
      ) {
        newMemberships++;
      } else {
        renewals++;
      }
    });

    const countByType = {
      newMemberships,
      renewals,
    };
    return countByType;
  }

  countByOrigin(): any {
    let fromWeb = 0;
    let fromPresential = 0;

    this.memberships.forEach((membership) => {
      if (membership.administrativeData.paymentInfo?.paymentData.paymentMethod === PaymentMetod.PAGO_EN_LINEA || membership.auditData.creator === 'Matricula Digital') {
        fromWeb++;
      } else {
        fromPresential++;
      }
    });

    const countByOrigin = {
      fromWeb,
      fromPresential,
    };

    return countByOrigin;
  }

  async selectSeasons() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.SEASONS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if(res.response.length > 0){
          this.seasons = res.response;
        }
        this.loadingService.hideLoading();
      });
  }

  
  onSeasonSelected(season:Season){
    this.seasonSelected = season;
    this.statisticsData = this.defaultStatisticsData;
    this.getMembershipsBySeason();
  }

  async getMembershipsBySeason() {
    this.loadingService.showLoading();

    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'seasonId',
              parameterName: '@Option1',
              parameterValue: this.seasonSelected!.id,
            }
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response.length > 0) {
        this.memberships = res.response;
      }else{
        this.memberships = [];
      }
      this.getDailyStatistics();
      this.loadingService.hideLoading();
    });
  }

}
