import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../../environments/environment';
import {
  AcademyQualification,
  AcademyState,
} from '../../../../model/AcademyState';
import { Cancellation } from '../../../../model/Cancellation';
import { ClassGroup } from '../../../../model/ClassGroup';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { FOTO_GUILLO } from '../../../../model/const/const';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { DocumentsService } from '../../../../services/documents.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { InstructorSeasonsMemberQualificationComponent } from '../../../instructor/instructor-seasons-member-qualification/instructor-seasons-member-qualification.component';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';
import { MemberEditMembershipComponent } from '../member-edit-membership/member-edit-membership.component';
@Component({
  selector: 'app-member-courses',
  standalone: true,
  templateUrl: './member-courses.component.html',
  styleUrl: './member-courses.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    TableModule,
    TagModule,
    CommonModule,
    ButtonModule,
    MemberEditMembershipComponent,
    SplitButtonModule,
    ToastModule,
    MenuModule,
    TooltipModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    TagModule,
    SplitButtonModule,
    InstructorSeasonsMemberQualificationComponent,
    TooltipModule,
    InputTextareaModule,
  ],
})
export class MemberCoursesComponent implements OnInit {
  constructor(
    private documentsService: DocumentsService,
    private sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private gestionService: GestionService,
    private msalService: MsalService,
    private httpService: HttpClient,
    private administrationService:AdministrationService
  ) {}

  @Input()
  member!: Member;

  @Input()
  membershipsDatail: MembershipDetail[] = [];

  @Output()
  membershipIsUpdated: EventEmitter<any> = new EventEmitter();

  showCourses: boolean = false;
  showEditMembership: boolean = false;

  membershipSelected!: MembershipDetail;
  itemsEditMenu: MenuItem[] | undefined;
  documentPreviewSelected: any = null;
  showDocumentPreview: boolean = false;
  userName: string = '';
  cancellationReason: string = '';
  showCancellationDialog: boolean = false;
  membershipCancellationSelected: MembershipDetail | undefined;
  ngOnInit(): void {
    if (this.membershipsDatail.length > 0) {
      this.showCourses = true;
    }

    this.itemsEditMenu = [
      {
        label: 'Matricula',
        items: [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (event) => {},
          },
        ],
      },
      {
        label: 'Carnet',
        items: [
          {
            label: 'Reenviar Digital',
            icon: 'pi pi-send',
            command: () => {},
          },
          {
            label: 'Generar Físico',
            icon: 'pi pi-print',
            command: () => {},
          },
        ],
      },
      {
        label: 'Soporte',
        items: [
          {
            label: 'Generar Soporte',
            icon: 'pi pi-print',
            command: () => {},
          },
          {
            label: 'Reenviar Digital',
            icon: 'pi pi-send',
            command: () => {},
          },
        ],
      },
    ];
    this.userName =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
  }

  getSeverity(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'info';
      case AcademyQualification.APPROVED:
        return 'success';
      case AcademyQualification.FAILED:
        return 'danger';
      case AcademyQualification.PENDING:
        return 'warning';
      default:
        return 'warning';
    }
  }

  getStatus(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'En curso';
      case AcademyQualification.APPROVED:
        return 'Aprobado';
      case AcademyQualification.FAILED:
        return 'No Aprobado';
      case AcademyQualification.PENDING:
        return 'Sin Iniciar';
      default:
        return status.state;
    }
  }

  initEdit(item: MembershipDetail) {
    this.membershipSelected = item;
    this.showEditMembership = true;
  }

  closeEditDialog() {
    this.membershipSelected = this.membershipsDatail[0];
    this.showEditMembership = false;
  }

  updateCourseHistory() {
    this.membershipIsUpdated.emit();
  }

  showPreview(membership: Membership) {
    if (membership.administrativeData.transactionDocId) {
      this.loadingService.showLoading();
      this.documentsService
        .getDocument(membership.administrativeData.transactionDocId)
        .subscribe((res: HttpResponse) => {
          this.loadingService.hideLoading();

          if (res.response.id) {
            this.documentPreviewSelected = {
              documentName: 'Comprobante de Transacción',
              base64: this.sanitizer.bypassSecurityTrustResourceUrl(
                res.response.filestream
              ),
            };
            this.showDocumentPreview = true;
          } else {
            this.documentPreviewSelected = null;
            this.showDocumentPreview = false;

            this.messageService.add({
              severity: 'warn',
              summary: 'Sin Comprobante',
              detail: 'No se ha encontrado comprobante de transacción',
              life: 3000,
            });
          }
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'No se ha encontrado identificador de transacción para esta matricula',
        life: 3000,
      });
    }
  }

  showDigitalLicense(membership: MembershipDetail) {
    if (membership.member.digitalLicense?.generated) {
      this.loadingService.showLoading();
      this.documentsService
        .getDocument(membership.member.digitalLicense.documentId)
        .subscribe((res: HttpResponse) => {
          this.loadingService.hideLoading();

          if (res.response.id) {
            this.documentPreviewSelected = {
              documentName: `Carnet Digital ${membership.member.memberId}`,
              base64: this.sanitizer.bypassSecurityTrustResourceUrl(
                res.response.filestream
              ),
            };
            this.showDocumentPreview = true;
          } else {
            this.documentPreviewSelected = null;
            this.showDocumentPreview = false;

            this.messageService.add({
              severity: 'warn',
              summary: 'Sin Carnet Digital',
              detail: 'No se ha encontrado carnet Digital',
              life: 3000,
            });
          }
        });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Procesando Carnet Digital',
        detail:
          'El Carnet Digital aun esta en proceso de generación puede tardar unos 2 minutos Aproximadamente',
        life: 3000,
      });
    }
  }

  resendInformation(membership: MembershipDetail) {
    this.member.digitalLicense = {
      documentId: this.member.digitalLicense?.documentId || '',
      generated: false,
      isMigratedMember: false,
    };

    this.member.picture = this.member.picture || FOTO_GUILLO;

    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: this.member,
      type: AcademyAdministratorType.MEMBERS,
    };

    this.confirmationService.confirm({
      message: `¿Desea reenviar el Carnet Digital y la información de la matricula al correo ${this.member.contactInformation.email}?`,
      header: 'Confirmación de Reenvío',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        this.gestionService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            this.loadingService.hideLoading();
            if (res.response) {
              this.getMembershipInformation(membership);
              this.messageService.add({
                severity: 'success',
                summary: 'Carnet Digital Reenviado',
                detail: 'El carnet digital se ha reenviado correctamente',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'No se ha podido reenviar la información de la matricula',
                life: 3000,
              });
            }
          });
      },
    });
  }

  getMembershipInformation(membership: MembershipDetail) {
    this.loadingService.showLoading();
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'id',
              parameterName: '@Id',
              parameterValue: membership.id,
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.loadingService.hideLoading();
      if (res.response) {
        this.updateMembershipWelcomeMail(res.response[0] as Membership);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido reenviar la información de la matricula',
          life: 3000,
        });
      }
    });
  }

  updateMembershipWelcomeMail(membership: Membership) {
    this.loadingService.showLoading();
    membership.comunications!.welcome = false;

    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: membership,
      type: AcademyAdministratorType.MEMBERSHIPS,
    };

    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.loadingService.hideLoading();
      if (res.response) {
        this.messageService.add({
          severity: 'success',
          summary: 'Información de Matricula Reenviada',
          detail:
            'La información de la matricula se ha reenviado correctamente',
          life: 3000,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido reenviar la información de la matricula',
          life: 3000,
        });
      }
    });
  }

  onCancellation(membership: MembershipDetail) {
    this.cancellationReason = '';
    this.membershipCancellationSelected = membership;
    this.showCancellationDialog = true;
  }

  onAcceptCancellation() {
    const membership = this.membershipCancellationSelected!;
    this.confirmationService.confirm({
      message: `¿Desea anular la matricula de ${membership.member.name} ${membership.member.lastName}?`,
      header: 'Confirmación de Anulación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelMembership(membership);
        this.showCancellationDialog = false;
      },
    });
  }

  async cancelMembership(membership: MembershipDetail) {
    this.loadingService.showLoading();
    const cancellation: Cancellation = {
      cancelationData: {
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        creator: this.userName,
        client_ip: await this.getIpCliente(),
        origin: environment.clientUrl,
      },
      cancellationReason: this.cancellationReason,
      membershipCancellation: membership,
      id: uuidv4(),
    };
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.CREATE,
      payload: cancellation,
      type: AcademyAdministratorType.CANCELLATIONS,
    };

    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response) {
        this.discountMemberOfClassGroup(membership);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido anular la matricula',
          life: 3000,
        });
      }
    });
  }

  async discountMemberOfClassGroup(membership: MembershipDetail) {
    let classGroup = membership.classGroup;
    let group: ClassGroup = {
      classGroupId: classGroup.classGroupId,
      courseId: classGroup.courseId,
      headquarterId: classGroup.headquarterId,
      instructorId: classGroup.instructorId,
      isRecurrent: classGroup.isRecurrent,
      levelId: classGroup.levelId,
      maxMembers: classGroup.maxMembers,
      schId: classGroup.schId,
      seasonId: classGroup.seasonId,
      id: classGroup.id,
      state: {
        classCount: classGroup.state.classCount,
        isActive: classGroup.state.isActive,
        membersCount:
          classGroup.state.membersCount > 0
            ? classGroup.state.membersCount - 1
            : 0,
        sessionsNumber: classGroup.state.sessionsNumber,
        classRegister: classGroup.state.classRegister,
      },
    };
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: group,
      type: AcademyAdministratorType.GROUPS,
    };
    this.administrationService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response) {
        this.deleteMembership(membership);
      } else {
        this.messageService.add({
          icon: 'pi pi-exclamation-triangle',
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido anular la matricula',
          life: 3000,
        });
      }
    });
  }

  async deleteMembership(membership: MembershipDetail) {
    const entityToDelete = {
      id: membership.id,
      membershipId: membership.membershipId,
    };
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: entityToDelete,
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
    
      if (res.response) {
          this.loadingService.hideLoading();
          this.updateCourseHistory();      
      } else {
        this.messageService.add({
          severity: 'error',
          icon: 'pi pi-exclamation-triangle',
          summary: 'Error',
          detail: 'Ocurrió un error anulando la matricula, inténtelo nuevamente si el error persiste contacte al administrador',
          life: 3000,
        });
      }
    });
  }

  // confirm() {
  //   this.confirmationService.confirm({
  //       header: 'Are you sure?',
  //       message: 'Please confirm to proceed.',
  //       accept: () => {
  //           this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  //       },
  //       reject: () => {
  //           this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  //       }
  //   });
  // }

  async getIpCliente(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.httpService
        .get('https://api.ipify.org/?format=json')
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }
}
