import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { v4 as uuidv4 } from 'uuid';
import { CrudTable } from '../../../model/CrudTable';
import { HttpResponse } from '../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { Discount } from '../../../model/administrator/AgreementsAndDiscounts/Discount';
import { Product } from '../../../model/administrator/ProductAndServices/Product';
import { DiscountType } from '../../../model/enum/DiscountType';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
  selector: 'app-descuentos-convenios',
  standalone: true,
  templateUrl: './descuentos-convenios.component.html',
  styleUrl: './descuentos-convenios.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    InputSwitchModule,
    InputTextModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
})
export class DescuentosConveniosComponent implements OnInit {
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };
  productsAndServices: Product[] = [];
  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];

  productTypes: any[] = [
    { label: 'CONVENIO', value: DiscountType.AGREEMENT },
    { label: 'DESCUENTO', value: DiscountType.DISCOUNT },
  ];
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit(): void {
    this.selectAgreementsAndDiscounts();
    this.selectProductAndServices();
  }

  async selectAgreementsAndDiscounts() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.AGREEMENTS_DISCOUNTS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Tipo',
          sorted: true,
          sortItem: 'type',
        },
        {
          label: 'Nombre',
          sorted: true,
          sortItem: 'name',
        },
        {
          label: '% Descuento',
          sorted: true,
          sortItem: 'discount',
        },
        {
          label: 'Descripción',
          sorted: true,
          sortItem: 'description',
        },
        {
          label: 'Activo',
          sorted: true,
          sortItem: 'isActive',
        },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: ['type', 'name', 'discount', 'description', 'isActive'],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Discount) {
    this.getFormRenewal(item);
  }

  deleteItem(item: Discount) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.DELETE,
          payload: item,
          type: AcademyAdministratorType.AGREEMENTS_DISCOUNTS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectAgreementsAndDiscounts();
            this.loadingService.hideLoading();
          });
      },
    });
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      type: [null, [Validators.required]],
      discount: [null, [Validators.required]],
      description: [null, [Validators.required]],
      isActive: [true, [Validators.required]],
      productsToApply: [null, [Validators.required]]
    });
    this.dialogHeader = 'Nuevo Descuento o Convenio';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Discount) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      name: [item.name, [Validators.required]],
      type: [item.type, [Validators.required]],
      discount: [item.discount, [Validators.required]],
      description: [item.description, [Validators.required]],
      isActive: [item.isActive, [Validators.required]],
      agreementsDiscountId: [item.agreementsDiscountId],
      productsToApply: [item.ProductsToApply || this.productsAndServices, [Validators.required]]
    });
    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.AGREEMENTS_DISCOUNTS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectAgreementsAndDiscounts();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.AGREEMENTS_DISCOUNTS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectAgreementsAndDiscounts();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Discount {
    let item: Discount = {
      name: form['name'].toUpperCase(),
      type: form['type'],
      agreementsDiscountId: form['agreementsDiscountId'] || uuidv4(),
      discount: form['discount'],
      description: form['description'],
      isActive: form['isActive'],
      ProductsToApply: form['productsToApply']
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }

  async selectProductAndServices() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.PRODUCTS_SERVICES,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.productsAndServices = res.response;
        this.loadingService.hideLoading();
      });
  }
}
