<app-page-header
  [title]="'Informes'"
  [subtitle]="'Módulo de Consulta y generación de Informes'"
></app-page-header>
<div class="card">
  <p-tabMenu
    [model]="items"
    [activeItem]="activeItem"
    (activeItemChange)="onActiveItemChange($event)"
  ></p-tabMenu>
</div>

<div>
  <div *ngIf="showLegalization">
    <app-memberships-legalizations></app-memberships-legalizations>
  </div>
  <div *ngIf="showMatriculas">
    <app-memberships-report></app-memberships-report>
  </div>
</div>
