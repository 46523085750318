<app-page-header
  [title]="'Ciclos'"
  [subtitle]="'Módulo de administración de ciclos'"
></app-page-header>
<app-crud-table
  [crudTable]="crudTableData"
  (itemEdit)="editItem($event)"
  (itemDelete)="deleteItem($event)"
  (itemAdd)="addItem()"
></app-crud-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="true"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <label for="name">Nombre</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese nombre para el ciclo"
          formControlName="name"
        />
        <small id="name-small">Nombre del ciclo.</small>

        <label for="startDate">Fecha Inicio</label>
        <p-calendar class="form-calendar-input" [touchUI]="true"  [autoZIndex]="false" formControlName="startDate" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
        <small id="address-small">Fecha de inicio de clases</small>

        <label for="endDate">Fecha Finalización</label>
        <p-calendar class="form-calendar-input" [touchUI]="true"  [autoZIndex]="false" formControlName="endDate" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
        <small id="address-small">Fecha finalización de clases.</small>

        <label for="availableToRegister">Disponible Matrícula en Sedes</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="availableToRegister"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>

        <label for="availableToAdministrativeGestion">Disponible Matrícula Digital</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="avaliableToDigitalRegister"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      
        <label for="availableToAdministrativeGestion">Disponible Instructores</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="availableToAdministrativeGestion"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>

        <label for="comments">Comentarios del ciclo</label>
        <textarea rows="5" cols="30" pInputTextarea formControlName="comments"></textarea>
    
    </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="showForm = false"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>
<div *ngIf="showDependencies"> 
<app-dependencies-list [dependencies]="dependenciesToDelete" (onCloseDependencies)="closeDependenceDialog"></app-dependencies-list>
</div>
