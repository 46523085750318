import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../../../../../environments/environment';
import {
  AcademyQualification,
  AcademyState,
} from '../../../../model/AcademyState';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Product } from '../../../../model/administrator/ProductAndServices/Product';
import { AdministrationService } from '../../../../services/administration.service';
import { DocumentsService } from '../../../../services/documents.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { InstructorSeasonsMemberQualificationComponent } from '../../../instructor/instructor-seasons-member-qualification/instructor-seasons-member-qualification.component';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';
import { FormLegalizationComponent } from '../../legalization/form-legalization/form-legalization.component';

@Component({
  selector: 'app-memberships-legalizations',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  templateUrl: './memberships-legalizations.component.html',
  styleUrl: './memberships-legalizations.component.scss',
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    TagModule,
    SplitButtonModule,
    InstructorSeasonsMemberQualificationComponent,
    TooltipModule,
    FormLegalizationComponent,
  ],
})
export class MembershipsLegalizationsComponent implements OnInit {
  @Output()
  closeEditDialog: EventEmitter<any> = new EventEmitter();

  memberships: Membership[] = [];
  membershipsDetail: MembershipDetail[] = [];
  membershipSelected!: MembershipDetail;
  header: string = '';
  showForm: boolean = false;
  showDialogForm: boolean = false;
  showMemberships: boolean = true;
  loading: boolean = false;
  showDocumentPreview: boolean = false;
  showLegalizeForm: boolean = false;
  documentPreviewSelected: any = null;
  legalizationModalHeader: string = '';
  legalizeMembershipSelected!: Membership;
  userName: string = '';
  viewSelected: any = '';
  membershipsWithMember: any[] = [];
  viewOptions: any[] = [
    { label: 'Pendientes Legalización', value: 'pendigLegalize' },
    { label: 'Legalizados', value: 'legalized' },
  ];

  constructor(
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private gestionService: GestionService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private msalService: MsalService,
    private documentsService: DocumentsService,

    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.userName =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
    this.viewSelected = 'pendigLegalize';
    this.getDataOfView();
  }

  getDataOfView() {
    switch (this.viewSelected) {
      case 'pendigLegalize':
        this.getMemberships(false);
        break;
      case 'legalized':
        this.getMemberships(true);
        break;
    }
  }

  async getMemberships(legalizedStatus: boolean) {
    this.loadingService.showLoading();
    this.loading = true;
    this.memberships = [];
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty:
                'administrativeData.legalizationInfo.isLegalized',
              parameterName: '@Option1',
              parameterValue: legalizedStatus,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '=',
              evaluatedProperty: 'state.state',
              parameterName: '@Option2',
              parameterValue: 'Active',
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService
      .sendRequest(entity)
      .subscribe(async (res: HttpResponse) => {
        if (res.response.length > 0) {
          this.memberships = res.response;
          this.loading = false;
          await this.mapperMemberInMembership(res.response);
        } else {
          this.memberships = res.response;
          this.loading = false;
          this.showForm = true;
          this.showMemberships = true;
          this.loadingService.hideLoading();
        }
        // this.memberships = res.response;
        // this.loading = false;
        // this.showForm = true;
        // this.showMemberships = true;
        this.loadingService.hideLoading();
      });
  }

  cancelDialogForm() {
    this.showDialogForm = false;
    this.closeEditDialog.emit();
  }

  showPreview(membership: Membership) {
    if (membership.administrativeData.transactionDocId) {
      this.loadingService.showLoading();
      this.documentsService
        .getDocument(membership.administrativeData.transactionDocId)
        .subscribe((res: HttpResponse) => {
          this.loadingService.hideLoading();

          if (res.response.id) {
            this.documentPreviewSelected = {
              documentName: 'Comprobante de Transacción',
              base64: this.sanitizer.bypassSecurityTrustResourceUrl(
                res.response.filestream
              ),
            };
            this.showDocumentPreview = true;
          } else {
            this.documentPreviewSelected = null;
            this.showDocumentPreview = false;

            this.confirmationService.confirm({
              message: `No hemos encontrado un soporte asociado a esta transacción, si es una Matricula Digital valide el estado de la transacción y obtenga el comprobante`,
              header: 'Soporte No Encontrado',
              acceptLabel: 'Validar Transacción',
              rejectLabel: 'Cancelar',
              rejectButtonStyleClass: 'p-button-outlined p-button-sm',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                const urlToValidate =
                  membership.administrativeData.paymentInfo?.productsAndServices.some(
                    (producto: Product) => producto.name === 'AFILIACIÓN'
                  )
                    ? `${environment.academyPortals.applications}/academy/registration/new-registration/${membership.administrativeData.transactionDocId}/payment/response`
                    : `${environment.academyPortals.applications}/academy/registration/renewal-registration/${membership.administrativeData.applicationId}/payment/response`;
                const url =urlToValidate;
                window.open(url, '_blank');
              },
            });
          }
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'No se ha encontrado identificador de transacción para esta matricula',
        life: 3000,
      });
    }
  }

  async mapperMemberInMembership(memberships: Membership[]) {
    this.membershipsWithMember = [];

    for (let membership of memberships as any[]) {
      let member = await this.getMemberById(membership.memberId);
      if (member.length > 0) {
        membership['member'] = member[0];
      } else {
        membership['member'] = {
          name: 'No Encontrado',
          lastName: '',
          genere: 'No Asignado',
          birthDay: 'No Asignado',
        };
      }

      this.membershipsWithMember.push(membership);
    }
    this.showForm = true;
    this.showMemberships = true;
    this.loadingService.hideLoading();
  }

  async getMemberById(id: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: 'memberId',
                parameterName: '@Id',
                parameterValue: id,
              },
            ],
          },
        },
        type: AcademyAdministratorType.MEMBERS_MASSIVE,
      };
      this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
        if (res.response) {
          resolve(res.response);
        }
      });
    });
  }

  clear(table: Table) {
    table.clear();
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  getSeverity(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'info';
      case AcademyQualification.APPROVED:
        return 'success';
      case AcademyQualification.FAILED:
        return 'danger';
      case AcademyQualification.PENDING:
        return 'warning';
      default:
        return 'warning';
    }
  }

  getStatus(status: boolean): string {
    switch (status) {
      case true:
        return 'Legalizado';
      case false:
        return 'Pendiente Legalización';
    }
  }

  getMemberGenere(genereShort: string): string {
    switch (genereShort) {
      case 'NB':
        return 'No Binario';
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Femenino';
      default:
        return 'No Especifica';
    }
  }
  exportExcel() {
    import('xlsx').then((xlsx) => {
      let dataToPrint: [] = this.getDataToPrint();
      const worksheet = xlsx.utils.json_to_sheet(dataToPrint);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.header);
    });
  }

  getDataToPrint(): any {
    switch (this.viewSelected) {
      case 'pendigLegalize':
        this.header = `Reporte de Matriculas Pendientes por Legalizar ${moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )}`;
        const dataToPrintPending = this.memberships.map(
          (membership: Membership) => {
            let memberDetailed = {
              Alumno: membership.memberId,
              'Método pago':
                membership.administrativeData.paymentInfo?.paymentData
                  .paymentMethod,
              Valor:
                membership.administrativeData.paymentInfo?.paymentData.amount,
              'Fecha Matricula': membership.auditData.created_at,
              'Matriculado por': membership.auditData.creator,
              Estado:
                membership.administrativeData.legalizationInfo?.isLegalized ||
                false,
            };

            return memberDetailed;
          }
        );

        return dataToPrintPending;
      case 'legalized':
        this.header = `Reporte de Matriculas Legalizadas ${moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )}`;
        const dataToPrintLegalized = this.memberships.map(
          (membership: Membership) => {
            let memberDetailed = {
              Alumno: membership.memberId,
              'Método pago':
                membership.administrativeData.paymentInfo?.paymentData
                  .paymentMethod,
              Valor:
                membership.administrativeData.paymentInfo?.paymentData.amount,
              'Fecha Matricula': membership.auditData.created_at,
              'Matriculado por': membership.auditData.creator,
              Estado:
                membership.administrativeData.legalizationInfo?.isLegalized ||
                false,
              'Fecha Legalización':
                membership.administrativeData.legalizationInfo?.leagalized_at,
              Legalizó:
                membership.administrativeData.legalizationInfo?.legalized_by,
              Factura:
                membership.administrativeData.legalizationInfo
                  ?.receiptOfPayment,
            };

            return memberDetailed;
          }
        );

        return dataToPrintLegalized;
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  initLegalization(membership: Membership) {
    this.legalizationModalHeader = `Legalizar Transacción`;
    this.legalizeMembershipSelected = membership;
    this.showLegalizeForm = true;
  }

  onCloseLegalizeForm() {
    this.legalizeMembershipSelected = undefined!;
    this.showLegalizeForm = false;
  }

  transactionLegalized() {
    this.legalizeMembershipSelected = undefined!;
    this.showLegalizeForm = false;
    this.getMemberships(false);
  }

  downloadTransactionSupportDoc(membership: Membership) {}
}
