import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { environment } from '../../../../../environments/environment';
import { ClassGroup, ClassGroupDetail } from '../../../../model/ClassGroup';
import { Course } from '../../../../model/Course';
import { Headquarters } from '../../../../model/Headquarter';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Level } from '../../../../model/Level';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { Sch } from '../../../../model/Sch';
import { Season } from '../../../../model/Season';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Discount } from '../../../../model/administrator/AgreementsAndDiscounts/Discount';
import { Product } from '../../../../model/administrator/ProductAndServices/Product';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { ApplicationService } from '../../../../services/application.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';

import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { v4 as uuidv4 } from 'uuid';
import { AcademyQualification } from '../../../../model/AcademyState';
import {
  LegalizationType,
  PaymentMetod,
} from '../../../../model/AdministrationData';
import { FOTO_GUILLO } from '../../../../model/const/const';
import { MemberResumeMembershipComponent } from '../member-resume-membership/member-resume-membership.component';
@Component({
  selector: 'app-member-create-membership',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  templateUrl: './member-create-membership.component.html',
  styleUrl: './member-create-membership.component.scss',
  imports: [
    PageHeaderComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    DropdownModule,
    CardModule,
    MultiSelectModule,
    RadioButtonModule,
    MemberResumeMembershipComponent,
    DividerModule,
    ToastModule,
  ],
})
export class MemberCreateMembershipComponent implements OnInit {
  headqueters: Headquarters[] = [];
  seasons: Season[] = [];
  courses: Course[] = [];
  levels: Level[] = [];
  groups: ClassGroup[] = [];
  availableGroups: ClassGroupDetail[] = [];
  validateForm!: FormGroup;
  showForm: boolean = false;
  showDialogForm: boolean = false;
  showResumeMembership: boolean = false;
  showResumeDialog: boolean = false;
  @Input()
  isNewForm: boolean = true;

  @Input()
  isUpdateForm: boolean = false;

  @Input()
  membershipDetail: MembershipDetail[] = [];

  @Input()
  member!: Member;

  @Output()
  membershipUpdated: EventEmitter<any> = new EventEmitter();

  @Output()
  closeEditDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  refreshMeberships: EventEmitter<any> = new EventEmitter();

  productsAndServices: Product[] = [];
  agreementsAndDiscounts: Discount[] = [];

  paymentMetods: any[] = [
    {
      id: 1,
      value: PaymentMetod.TRANSFERENCIA_BANCARIA,
    },
    {
      id: 2,
      value: PaymentMetod.CONSINGNACION_BANCARIA,
    },
    {
      id: 3,
      value: PaymentMetod.TARJETA_CREDITO,
    },
    {
      id: 4,
      value: PaymentMetod.TARJETA_DEBITO,
    },
    {
      id: 5,
      value: PaymentMetod.PAGO_EN_LINEA,
    },
  ];

  academyQualifications: any[] = [
    {
      id: 1,
      value: AcademyQualification.APPROVED,
      label: 'APROBADO',
    },
    {
      id: 2,
      value: AcademyQualification.FAILED,
      label: 'REPROBADO',
    },
    {
      id: 3,
      value: AcademyQualification.PENDING,
      label: 'SIN INICIAR',
    },
    {
      id: 4,
      value: AcademyQualification.IN_PROGRESS,
      label: 'EN CURSO',
    },
    {
      id: 5,
      value: AcademyQualification.CANCELED,
      label: 'CANCELADO',
    },
  ];

  totalAmout: number = 0;
  userName: string = '';

  membershipResumen!: MembershipDetail;
  header: string = 'Nueva Matricula';
  constructor(
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private applicationService: ApplicationService,
    private msalService: MsalService,
    private httpService: HttpClient,
    private gestionService: GestionService
  ) {}
  ngOnInit(): void {
    this.buildMasterOptions();
    this.selectAgreementsDiscount();
    this.selectProductAndServices();
    this.userName =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
  }

  async buildMasterOptions() {
    this.headqueters = await this.getHeadquarter();
    this.courses = await this.getCourses();
    this.seasons = await this.getSeasons();

    if (this.isNewForm) {
      this.getFormNew();
      this.showDialogForm = false;
      this.header = `Nueva matricula para ${this.member.name} ${
        this.member.lastName
      } | ${this.calculateYearsOld(this.member.birthDay)} Años`;
    } else if (this.isUpdateForm) {
      this.getFormEdit();
      this.showDialogForm = true;
      this.header = `Editar matricula de ${this.member.name} ${
        this.member.lastName
      } | ${this.calculateYearsOld(this.member.birthDay)} Años`;

      await this.getAvailableGroups();
    }
    this.showForm = true;
  }

  async getHeadquarter(): Promise<Headquarters[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.HEADQUARTERS,
      true,
      'isActive'
    );
    return groupData as Headquarters[];
  }

  async getCourses(): Promise<Course[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      true,
      'isActive'
    );
    return groupData as Course[];
  }

  async getSeasons(): Promise<Season[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SEASONS,
      true,
      'availableToRegister'
    );
    return groupData as Season[];
  }

  async getAvailableGroups() {
    this.availableGroups = [];
    let form = this.validateForm.value;
    if (
      (form['headquarter'] && form['course'] && form['level'], form['season'])
    ) {
      this.loadingService.showLoading();
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: 'headquarterId',
                parameterName: '@Option1',
                parameterValue: form['headquarter']['id'],
              },
              {
                conditionalConnector: 'AND',
                logicOperator: '=',
                evaluatedProperty: 'courseId',
                parameterName: '@Option2',
                parameterValue: form['course']['id'],
              },
              {
                conditionalConnector: 'AND',
                logicOperator: '=',
                evaluatedProperty: 'levelId',
                parameterName: '@Option3',
                parameterValue: form['level']['id'],
              },
              {
                conditionalConnector: 'AND',
                logicOperator: '=',
                evaluatedProperty: 'seasonId',
                parameterName: '@Option4',
                parameterValue: form['season']['id'],
              },
              {
                conditionalConnector: 'AND',
                logicOperator: '=',
                evaluatedProperty: 'state.isActive',
                parameterName: '@Option5',
                parameterValue: true,
              },
            ],
          },
        },
        type: AcademyAdministratorType.GROUPS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          this.groups = (res.response as ClassGroup[]) || [];
          this.getDetailOfGroups();
        });
    }
  }

  async getDetailOfGroups() {
    for await (let group of this.groups) {
      let groupDetail = await this.getSchDetail(group.schId);
      let classGroupDetail: ClassGroupDetail = {
        ...group,
        sch: groupDetail,
        name: `${group.classGroupId} - ${groupDetail.days} ${groupDetail.startTime} - ${groupDetail.endTime} | Inscritos: ${group.state.membersCount} de ${group.maxMembers}`,
        isComplete: group.state.membersCount < group.maxMembers ? false : true,
      };
      if (
        !this.availableGroups?.some((x) => x.id === classGroupDetail.id) &&
        this.isValidGroup(classGroupDetail.id)
      ) {
        this.availableGroups.push(classGroupDetail);
      }
    }
    if (this.isUpdateForm) {
      this.validateForm
        .get('classGroup')
        ?.setValue(
          this.availableGroups.find(
            (group) => group.id === this.membershipDetail[0].classGroup.id
          )
        );
    }
    this.loadingService.hideLoading();
  }

  getAvailableLevels() {
    let availableLevels: Level[] = [];
    let majorLevelApprovedOrder = -1;
    let pendingOrInPorgressLevelOrder = -1;

    for (let level of this.validateForm
      .get('course')!
      .value['levels'].reverse() as Level[]) {
      const academyQualification: AcademyQualification =
        this.getAcademyQualificationOfLevel(level.id);

      switch (academyQualification) {
        case AcademyQualification.APPROVED:
          level.disableToRegister = true;
          level.levelOrder > majorLevelApprovedOrder
            ? (majorLevelApprovedOrder = level.levelOrder)
            : majorLevelApprovedOrder;
          break;
        case AcademyQualification.NOT_REGISTERED:
          if (
            majorLevelApprovedOrder !== -1 &&
            level.levelOrder < majorLevelApprovedOrder
          ) {
            level.disableToRegister = true;
          }
          break;

        case AcademyQualification.FAILED:
          level.disableToRegister = false;
          level.levelOrder > majorLevelApprovedOrder
            ? (majorLevelApprovedOrder = level.levelOrder)
            : majorLevelApprovedOrder;

          pendingOrInPorgressLevelOrder = level.levelOrder;
          break;

        case AcademyQualification.CANCELED:
          level.levelOrder > majorLevelApprovedOrder
            ? (majorLevelApprovedOrder = level.levelOrder)
            : majorLevelApprovedOrder;
          break;

        case AcademyQualification.PENDING:
          level.disableToRegister = false;
          level.levelOrder > majorLevelApprovedOrder
            ? (majorLevelApprovedOrder = level.levelOrder)
            : majorLevelApprovedOrder;

          pendingOrInPorgressLevelOrder = level.levelOrder;
          break;

        case AcademyQualification.IN_PROGRESS:
          level.disableToRegister = false;
          level.levelOrder > majorLevelApprovedOrder
            ? (majorLevelApprovedOrder = level.levelOrder)
            : majorLevelApprovedOrder;
          pendingOrInPorgressLevelOrder = level.levelOrder;
          break;

        default:
          level.disableToRegister = false;
          break;
      }

      availableLevels.push(level);
    }

    if (pendingOrInPorgressLevelOrder > -1) {
      this.levels = availableLevels
        .sort((a: Level, b: Level) => b.levelOrder - a.levelOrder)
        .map((level: Level) => {
          level.levelOrder !== pendingOrInPorgressLevelOrder
            ? (level.disableToRegister = true)
            : (level.disableToRegister = false);
          return level;
        });
    } else {
      this.levels = availableLevels.sort(
        (a: Level, b: Level) => b.levelOrder - a.levelOrder
      );
    }

    this.getAvailableGroups();
  }

  getAcademyQualificationOfLevel(id?: string): AcademyQualification {
    let qualification = AcademyQualification.NOT_REGISTERED;
    for (let membership of this.membershipDetail) {
      if (membership.level?.id === id) {
        qualification = membership.academyState.state;
      }
    }

    return qualification;
  }

  isValidGroup(id?: string): boolean {
    let isValid = true;
    for (let membership of this.membershipDetail) {
      if (
        this.isNewForm &&
        membership.classGroup?.id === id &&
        (membership.academyState.state === AcademyQualification.PENDING ||
          membership.academyState.state === AcademyQualification.IN_PROGRESS)
      ) {
        isValid = false;
      }
    }

    return isValid;
  }

  async getSchDetail(id: string): Promise<Sch> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      id,
      'id'
    );
    return groupData[0] as Sch;
  }
  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      headquarter: [null, [Validators.required]],
      course: [null, [Validators.required]],
      level: [null, [Validators.required]],
      season: [null, [Validators.required]],
      classGroup: [null, [Validators.required]],
      productsAndServices: [null, [Validators.required]],
      agreementsAndDiscounts: [[]],
      paymentMethod: [null, [Validators.required]],
    });
  }

  getFormEdit() {
    this.validateForm = this.fb.group({
      id: [this.membershipDetail[0].id],
      headquarter: [
        this.membershipDetail[0].headquarter,
        [Validators.required],
      ],
      course: [this.membershipDetail[0].course, [Validators.required]],
      level: [this.membershipDetail[0].level, [Validators.required]],
      season: [this.membershipDetail[0].season, [Validators.required]],
      classGroup: [this.membershipDetail[0].classGroup, [Validators.required]],
      academyQualification: [
        this.membershipDetail[0].academyState.state,
        [Validators.required],
      ],
      paymentMethod: [
        this.membershipDetail[0].administrativeData.paymentInfo?.paymentData
          .paymentMethod,
        [Validators.required],
      ],
      productsAndServices: [
        this.membershipDetail[0].administrativeData.paymentInfo
          ?.productsAndServices,
        [Validators.required],
      ],
      agreementsAndDiscounts: [
        this.membershipDetail[0].administrativeData.paymentInfo
          ?.agreementsDiscount || [],
      ],
      transactionDocId: [
        this.membershipDetail[0].administrativeData.transactionDocId,
      ],
      applicationId: [
        this.membershipDetail[0].administrativeData.applicationId || 'Registro Presencial',
      ],
      paymentId: [
        this.membershipDetail[0].administrativeData.paymentInfo?.paymentId ?? uuidv4(),
      ],
      attendance: [
        this.membershipDetail[0].academyState.attendances || []
      ],
      welcome: [this.membershipDetail[0].comunications?.welcome || false],
    });
  }

  async addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = await this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.MEMBERSHIPS,
      };
      this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
        if (res.code === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'El registro se ha creado correctamente!',
            life: 3000,
          });
          let groupToUpdate = this.groups.find(
            (group: ClassGroup) =>
              group.id === this.validateForm.value['classGroup']['id']
          );
          //Temporal se actualiza durante migracion Academy V1 a V2 y controlar el envio de licencia digital
          if (this.member.digitalLicense?.isMigratedMember) {
            this.updateMigratedMember(this.member);
          }
          this.updateMemberCountOnGroup(groupToUpdate);
          this.buildMembershipResumen(item);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
            life: 3000,
          });
        }

        this.showForm = false;
        this.showDialogForm = false;
        this.loadingService.hideLoading();
      });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.loadingService.showLoading();
        const item = await this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.MEMBERSHIPS,
        };
        this.gestionService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
              if (this.validateChangeOfGroup()) {
                let targetGroup = this.groups.find(
                  (group: ClassGroup) =>
                    group.id === this.validateForm.value['classGroup']['id']
                );
                this.updateMemberCountOnGroup(
                  targetGroup,
                  this.membershipDetail[0].classGroup
                );
              }
              if (this.member.digitalLicense?.isMigratedMember) {
                this.updateMigratedMember(this.member);
              }
              this.cancelDialogForm();
              this.showForm = false;
              this.membershipUpdated.emit();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.loadingService.hideLoading();
          });
      },
    });
  }

  async buildAcademyItem(form: any): Promise<Membership> {
    let item: Membership = {
      memberId: this.member.memberId,
      membershipId: this.member.memberId,
      headquarterId: form['headquarter']['id'],
      classGroupId: form['classGroup']['id'],
      courseId: form['course']['id'],
      levelId: form['level']['id'],
      seasonId: form['season']['id'],
      academyState: {
        attendances:  form['attendance'] || [],
        state: this.isUpdateForm
          ? form['academyQualification']
          : AcademyQualification.PENDING,
      },
      administrativeData: {
        transactionDocId: this.isUpdateForm
          ? form['transactionDocId']
          : uuidv4(),
        applicationId: form['applicationId'],
        paymentInfo: {
          agreementsDiscount: form['agreementsAndDiscounts'],
          productsAndServices: form['productsAndServices'],
          paymentData: {
            paymentMethod: form['paymentMethod'],
            amount: this.calculateAmount(
              form['productsAndServices'],
              form['agreementsAndDiscounts']
            ),
          },
          paymentId: form['paymentId'] || uuidv4(),
        },
        legalizationInfo: {
          isLegalized: false,
          leagalized_at: '',
          legalized_by: '',
          legalizedType: LegalizationType.MANUAL,
          receiptOfPayment: '',
        },
      },
      auditData: {
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        creator: this.userName,
        client_ip: await this.getIpCliente(),
        origin: environment.clientUrl,
      },
      state: {
        state: 'Active',
      },
      comunications: {
        welcome:  form['welcome'] ||  false,
      },
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }

  async getIpCliente(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.httpService
        .get('https://api.ipify.org/?format=json')
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }

  updateMemberCountOnGroup(targetGroup?: ClassGroup, originGroup?: ClassGroup) {
    if (targetGroup) {
      let newMemberCount = targetGroup.state.membersCount + 1;
      targetGroup.state.membersCount = newMemberCount;
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.UPDATE,
        payload: targetGroup,
        type: AcademyAdministratorType.GROUPS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
        });
    }
    if (originGroup) {
      let newMemberDiscount = originGroup.state.membersCount - 1;
      originGroup.state.membersCount = newMemberDiscount;
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.UPDATE,
        payload: originGroup,
        type: AcademyAdministratorType.GROUPS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
        });
    }
  }

  async selectProductAndServices() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.PRODUCTS_SERVICES,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.productsAndServices = res.response as Product[];
        this.loadingService.hideLoading();
      });
  }

  async selectAgreementsDiscount() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.AGREEMENTS_DISCOUNTS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.agreementsAndDiscounts = res.response as Discount[];
        this.loadingService.hideLoading();
      });
  }

  calculateAmount(
    productsAndServices: Product[],
    agreementsAndDiscounts: Discount[]
  ): number {
    let totalProductAndService = 0;
    if (productsAndServices.length > 0) {
      for (let product of productsAndServices) {
        let totalProduct = this.calculateProductTotal(product, agreementsAndDiscounts);
        totalProductAndService += totalProduct;
      }
    }

    const amount = totalProductAndService;
    this.totalAmout = amount;
    return amount;
  }

  private calculateProductTotal(product: Product, agreementsAndDiscounts: Discount[]): number {
    let taxAdd = 0;
    if (product.hasTax) {
      taxAdd = (product.taxValue / 100) * product.price;
    }
    let totalProduct = product.price + taxAdd;

    if (agreementsAndDiscounts.length > 0) {
      let discount = this.findDiscountForProduct(product, agreementsAndDiscounts);
      if (discount) {
        totalProduct -= (discount.discount / 100) * totalProduct;
      }
    }

    return totalProduct;
  }

  private findDiscountForProduct(product: Product, agreementsAndDiscounts: Discount[]): Discount | undefined {
    return agreementsAndDiscounts.find((discount: Discount) =>
      discount.ProductsToApply?.some(
        (productToDiscount: Product) => productToDiscount.id === product.id
      )
    );
  }

  cancelDialogForm() {
    this.showDialogForm = false;
    this.validateForm.reset();
    this.isNewForm ? this.getFormNew() : this.getFormEdit;
    this.closeEditDialog.emit();
  }

  buildMembershipResumen(membership: Membership) {
    const membershipDetail: MembershipDetail = {
      ...membership,
      member: this.member,
      headquarter: this.validateForm.value['headquarter'],
      course: this.validateForm.value['course'],
      season: this.validateForm.value['season'],
      level: this.validateForm.value['level'],
      classGroup: this.validateForm.value['classGroup'],
    };

    this.membershipResumen = membershipDetail;
    this.showResumeMembership = true;
    this.showResumeDialog = true;
  }

  validateChangeOfGroup(): boolean {
    return (
      this.membershipDetail[0].classGroup.id !==
      this.validateForm.get('classGroup')?.value.id
    );
  }

  async updateMigratedMember(member: Member) {
    this.loadingService.showLoading();
    member.digitalLicense!.isMigratedMember = false;
    member.picture = FOTO_GUILLO;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: member,
      type: AcademyAdministratorType.MEMBERS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.code === 200) {
        this.messageService.add({
          severity: 'success',
          summary: 'OK',
          detail: 'Alumno migrado a la nueva versión de academy correctamente!',
          life: 3000,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Ocurrió un error migrando el alumno a la nueva versión del academy, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
          life: 3000,
        });
      }
      this.loadingService.hideLoading();
    });
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }
}
