import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { v4 as uuidv4 } from 'uuid';
import { ClassGroup } from '../../../model/ClassGroup';
import { CrudTable } from '../../../model/CrudTable';
import { Dependence } from '../../../model/Dependencie';
import { HttpResponse } from '../../../model/HttpResponse';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { ValidationService } from '../../../services/validation.service';
import { DependenciesListComponent } from '../../shared/dependencies-list/dependencies-list.component';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
  selector: 'app-ciclos',
  standalone: true,
  templateUrl: './ciclos.component.html',
  styleUrl: './ciclos.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    InputSwitchModule,
    InputTextModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    DependenciesListComponent,
  ],
})
export class AdminCiclosComponent implements OnInit {
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];
  showDependencies: boolean = false;
  dependenciesToDelete: Dependence[] = [];
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validationServices: ValidationService
  ) {}
  ngOnInit(): void {
    this.selectSeasons();
  }

  async selectSeasons() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.SEASONS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Nombre',
          sorted: true,
          sortItem: 'name',
        },
        {
          label: 'Comentarios',
          sorted: true,
          sortItem: 'comments',
        },
        {
          label: 'Fecha Inicio',
          sorted: true,
          sortItem: 'startDate',
        },
        {
          label: 'Fecha Fin',
          sorted: true,
          sortItem: 'endDate',
        },
        {
          label: 'Disponible Matricula Presencial',
          sorted: true,
          sortItem: 'availableToRegister',
        },
        {
          label: 'Disponible Matricula Digital',
          sorted: true,
          sortItem: 'avaliableToDigitalRegister',
        },
        {
          label: 'Disponible Instructores',
          sorted: true,
          sortItem: 'availableToAdministrativeGestion',
        },
        //   {
        //     label: 'Sedes',
        //     sorted: false,
        //     sortItem: null,
        //   },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: [
        'name',
        'comments',
        'startDate',
        'endDate',
        'availableToRegister',
        'avaliableToDigitalRegister',
        'availableToAdministrativeGestion',
      ],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Season) {
    this.getFormRenewal(item);
  }

  async deleteItem(item: Season) {
    this.dependenciesToDelete = [];
    this.showDependencies = false;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: item,
      type: AcademyAdministratorType.SEASONS,
    };
    let dependencies = await this.validationServices.dependenciesToDelete(
      entity
    );

    if (dependencies.length === 0) {
      this.confirmationService.confirm({
        message: 'Estas seguro de borrar este registro ?',
        header: 'Confirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loadingService.showLoading();

          this.administrationService
            .sendRequest(entity)
            .subscribe((res: HttpResponse) => {
              if (res.code === 200) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'El registro se ha eliminado correctamente!',
                  life: 3000,
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail:
                    'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                  life: 3000,
                });
              }
              this.selectSeasons();
              this.loadingService.hideLoading();
            });
        },
      });
    } else {
      this.dependenciesToDelete = dependencies.map(
        (dependencie: ClassGroup) => {
          const dependenciToList: Dependence = {
            id: dependencie.id,
            type: AcademyAdministratorType.GROUPS,
            name: dependencie.classGroupId,
          };
          return dependenciToList;
        }
      );
      this.showDependencies = true;
    }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      comments: [null],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      headquarters: [null],
      availableToRegister: [true, [Validators.required]],
      availableToAdministrativeGestion: [true, [Validators.required]],
      avaliableToDigitalRegister: [true, [Validators.required]],
    });
    this.dialogHeader = 'Nuevo Ciclo';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Season) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      name: [item.name, [Validators.required]],
      comments: [item.comments, []],
      startDate: [item.startDate, [Validators.required]],
      endDate: [item.endDate, [Validators.required]],
      availableToRegister: [item.availableToRegister, [Validators.required]],
      availableToAdministrativeGestion: [
        item.availableToAdministrativeGestion,
        [Validators.required],
      ],
      avaliableToDigitalRegister: [
        item.avaliableToDigitalRegister || false,
        [Validators.required],
      ],
      seasonId: [item.seasonId],
    });
    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  closeDependenceDialog() {
    this.showDependencies = false;
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.SEASONS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectSeasons();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.SEASONS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectSeasons();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Season {
    let item: Season = {
      name: form['name'].toUpperCase(),
      comments: form['comments'],
      seasonId: form['seasonId'] || uuidv4(),
      startDate: moment(form['startDate']).format('yyyy-MM-DD'),
      endDate: moment(form['endDate']).format('yyyy-MM-DD'),
      availableToRegister: form['availableToRegister'],
      availableToAdministrativeGestion:
        form['availableToAdministrativeGestion'],
      avaliableToDigitalRegister: form['avaliableToDigitalRegister'],
      headquarters: form['headquarters'],
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }
}
