<div *ngIf="showMemberships">
    <div class="membership-container" *ngFor="let membership of membershipsDatail">
        <p-card [header]="membership.level?.name" [subheader]="membership.course.name" class="membership-card card-cursos">
            <span class="member-detail">
                Horario: {{membership.classGroup.sch.days}}  {{membership.classGroup.sch.startTime}} -  {{membership.classGroup.sch.endTime}}
            </span>
            <span class="member-detail">
                Asistencias: {{membership.academyState.attendances.length}} / {{membership.classGroup.state.sessionsNumber}}
            </span>
            <span class="member-detail">
                Instructor: {{membership.classGroup.instructor?.name}}
            </span>
            <span class="member-detail">
                Fecha Matricula: {{membership.auditData.created_at}}
            </span>
            <span class="member-detail">
                Estado: {{getStatus(membership.academyState)}}
            </span>
            <ng-template pTemplate="footer">
                <p-button label="Registrar Ingreso" icon="pi pi-check" (click)="onMembershipCheckin(membership)" [disabled]="membership.academyState.attendances.length >= membership.classGroup.state.sessionsNumber"></p-button>
            </ng-template>
        </p-card>
    </div>
</div>

<div *ngIf="showNotFoundItems">
    <p-card header="Cursos No Disponibles" subheader="Sin información de cursos" class="card-cursos">
        <ng-template pTemplate="header">
            <i class="pi pi-inbox" style="font-size: 2.5rem"></i>
        </ng-template>
        <p>
            El alumno no presenta cursos inscritos para el {{season.name}} en la {{headquarter.name}}, intente seleccionar otro ciclo u otra sede
        </p>
        <ng-template pTemplate="footer">
            <p-button severity="secondary" label="Entendido" icon="pi pi-thumbs-up" (click)="onMembershipNotFound()"></p-button>
        </ng-template>
    </p-card>
</div>

<div *ngIf="showCheckinSuccess">
    <p-card header="Registro Exitoso!" subheader="Se registró la asistencia" class="card-cursos">
        <ng-template pTemplate="header">
            <i class="pi pi-inbox" style="font-size: 2.5rem"></i>
        </ng-template>
        <p>
          La asistencia de {{member.name}} {{member.lastName}} se registró correctamente
        </p>
        <ng-template pTemplate="footer">
            <p-button severity="secondary" label="Entendido" icon="pi pi-thumbs-up" (click)="onMembershipNotFound()"></p-button>
        </ng-template>
    </p-card>
</div>
<p-toast ></p-toast>
<p-confirmDialog></p-confirmDialog>