<p-menubar [model]="items" class="report-type__menu" />

<div>
  <div *ngIf="membershipReportType === 'daily'">
    <div class="report-daily__date" id="body">
      <label for="icondisplay" class="font-bold block mb-2">
        Fecha de reporte
      </label>
      <p-calendar
        [touchUI]="true"
        (onSelect)="onChangeDay($event)"
        [iconDisplay]="'input'"
        [showIcon]="true"
        inputId="icondisplay"
        [baseZIndex]="1000"
      />
    </div>
    <div class="report-cards">
      <p-card class="report-cards__card" header="Nuevos">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.countByType.newMemberships ?? 0 }}</span>
        </div>
      </p-card>
      <p-card class="report-cards__card" header="Renovaciones">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.countByType.renewals ?? 0 }}</span>
        </div>
      </p-card>
      <p-card class="report-cards__card" header="Total Matriculas">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.totalMemberships ?? 0 }}</span>
        </div>
      </p-card>
      <p-card class="report-cards__card" header="Total Ingresos">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.totalAmount ?? 0 | currency }}</span>
        </div>
      </p-card>
    </div>
    <div class="report-cards">
      <p-card class="report-cards__card-xl" header="Matricula Digital">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.countByOrigin.fromWeb ?? 0 }}</span>
        </div>
      </p-card>
      <p-card class="report-cards__card-xl" header="Matricula Presencial">
        <div class="report-cards__card-content">
          <span>{{ statisticsData.countByOrigin.fromPresential ?? 0 }}</span>
        </div>
      </p-card>
    </div>
  </div>
  <div *ngIf="membershipReportType === 'seasons'">
    <!-- <p-card header="En Construcción" class="m-2">
        <p class="m-2">
            Este reporte esta en construcción y estará disponible en futuras versiones.
        </p>
    </p-card> -->
    <label for="icondisplay" class="text-center m-2 font-bold block mb-2">
      Ciclo de reporte
    </label>

    <div class="card flex justify-content-center">
      <p-dropdown
        [options]="seasons"
        [(ngModel)]="seasonSelected"
        placeholder="Seleccione un ciclo"
        optionLabel="name"
        (ngModelChange)="onSeasonSelected($event)"
        id="icondisplay"
      ></p-dropdown>
    </div>

    <div *ngIf="seasonSelected">
      <div class="report-cards">
        <p-card class="report-cards__card" header="Nuevos">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.countByType.newMemberships ?? 0 }}</span>
          </div>
        </p-card>
        <p-card class="report-cards__card" header="Renovaciones">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.countByType.renewals ?? 0 }}</span>
          </div>
        </p-card>
        <p-card class="report-cards__card" header="Total Matriculas">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.totalMemberships ?? 0 }}</span>
          </div>
        </p-card>
        <p-card class="report-cards__card" header="Total Ingresos">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.totalAmount ?? 0 | currency }}</span>
          </div>
        </p-card>
      </div>
      <div class="report-cards">
        <p-card class="report-cards__card-xl" header="Matricula Digital">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.countByOrigin.fromWeb ?? 0 }}</span>
          </div>
        </p-card>
        <p-card class="report-cards__card-xl" header="Matricula Presencial">
          <div class="report-cards__card-content">
            <span>{{ statisticsData.countByOrigin.fromPresential ?? 0 }}</span>
          </div>
        </p-card>
      </div>
    </div>

  </div>
</div>
