<p-toolbar styleClass="mb-1 gap-1">
  <ng-template pTemplate="left">
      <p-selectButton [options]="viewOptions" [(ngModel)]="viewSelected" optionLabel="label" optionValue="value" (onChange)="getDataOfView()"></p-selectButton>
  </ng-template>
  <ng-template pTemplate="right">

  </ng-template>
</p-toolbar>

<div *ngIf="showForm">
      <div class="card" *ngIf="showMemberships">
        <p-table
          #dt1
          [value]="memberships"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loading"
          [paginator]="true"
          currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} registros"
          [globalFilterFields]="[
            'memberId',
            'member.name',
            'administrativeData.paymentData.paymentMethod',
            'administrativeData.paymentData.amount',
            'administrativeData.legalizationInfo.isLegalized',
            'administrativeData.legalizationInfo.receiptOfPayment',
            'auditData.created_at',
            'auditData.creator'
          ]"
           responsiveLayout="stack" [breakpoint]="'960px'"
        >
          <ng-template pTemplate="caption">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="exportExcel()"
                class="p-button-primary mr-2"
                pTooltip="Exportar Excel"
                tooltipPosition="bottom"
              ></button>
  
              <button
                pButton
                label="Limpiar Filtros"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                (click)="clear(dt1)"
              ></button>
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt1.filterGlobal($any($event.target).value, 'contains')
                  "
                  placeholder="Busqueda por palabras"
                />
              </span>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Id Alumno
                  <p-columnFilter
                    type="text"
                    field="memberId"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <!-- <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Nombre Alumno
                  <p-columnFilter
                    type="text"
                    field="member.name"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th> -->
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Método pago
                  <p-columnFilter
                    type="text"
                    field="administrativeData.paymentData.paymentMethod"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Valor
                  <p-columnFilter
                    type="text"
                    field="administrativeData.paymentData.amount"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
  
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                  Fecha Matricula
                  <p-columnFilter
                    type="text"
                    field="auditData.created_at"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                 Matriculado por
                  <p-columnFilter
                    type="text"
                    field="auditData.creator"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                  Estado
                  <p-columnFilter
                    type="text"
                    field="administrativeData.legalizationInfo.isLegalized"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem" *ngIf="viewSelected === 'legalized'">
                <div class="flex align-items-center">
                  Fecha Legalización
                  <p-columnFilter
                    type="text"
                    field="administrativeData.legalizationInfo.leagalized_at"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width:5rem" *ngIf="viewSelected === 'legalized'">
                <div class="flex align-items-center">
                  Legalizó
                  <p-columnFilter
                    type="text"
                    field="administrativeData.legalizationInfo.legalized_by"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem" *ngIf="viewSelected === 'legalized'">
                <div class="flex align-items-center">
                  Factura
                  <p-columnFilter
                    type="text"
                    field="administrativeData.legalizationInfo.receiptOfPayment"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                  Acciones
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-membership let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title font-bold">Id Alumno</span>
                {{ membership.memberId }}
              </td>
              <!-- <td>
                <span class="p-column-title font-bold">Nombre Alumno</span>
                {{ membership.member?.name }} {{ membership.member?.lastName }}
              </td> -->
              <td>
                <span class="p-column-title font-bold">Método de Pago</span>
                <span class="ml-1 vertical-align-middle">{{
                    membership.administrativeData.paymentInfo.paymentData.paymentMethod
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Valor</span>
                <span class="ml-1 vertical-align-middle">{{
                    membership.administrativeData.paymentInfo.paymentData.amount
                | currency}} </span>
              </td>
              <td>
                <span class="p-column-title font-bold">Fecha Matricula</span>
                <span class="ml-1 vertical-align-middle">{{
                    membership.auditData.created_at
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Matriculado por</span>
                <span class="ml-1 vertical-align-middle"
                  >{{ membership.auditData.creator }}</span
                >
              </td>
              <td>
                <span class="p-column-title font-bold">Estado</span>
                <span class="ml-1 vertical-align-middle">{{
                    getStatus(membership.administrativeData.legalizationInfo.isLegalized)
                }}</span>
              </td>
              <td *ngIf="viewSelected === 'legalized'">
                <span class="p-column-title font-bold">Fecha Legalización</span>
                <span class="ml-1 vertical-align-middle">{{
                   membership.administrativeData.legalizationInfo.leagalized_at
                }}</span>
              </td>
              <td *ngIf="viewSelected === 'legalized'">
                <span class="p-column-title font-bold">Legalizado por</span>
                <span class="ml-1 vertical-align-middle">{{
                   membership.administrativeData.legalizationInfo.legalized_by
                }}</span>
              </td>
              <td *ngIf="viewSelected === 'legalized'">
                <span class="p-column-title font-bold">Número de recibo</span>
                <span class="ml-1 vertical-align-middle">{{
                   membership.administrativeData.legalizationInfo.receiptOfPayment
                }}</span>
              </td>
              <td >
                <span class="p-column-title font-bold">Acciones</span>
                <button
                  pButton
                  pRipple
                  icon="pi pi-file-pdf"
                  class="p-button-rounded p-button-warning m-1"
                  (click)="showPreview(membership)"
                  [pTooltip]="'Ver Soporte Transacción'" tooltipPosition="bottom"
                ></button>
                <button
                  *ngIf="!membership.administrativeData.legalizationInfo.isLegalized"
                  pButton
                  pRipple
                  icon="pi pi-check-square"
                  class="p-button-rounded p-button-primary m-1"
                  (click)="initLegalization(membership)"
                  [pTooltip]="'Legalizar'" tooltipPosition="bottom"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No se encontraron transacciones en el estado seleccionado.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  </div>
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>
  <p-dialog
  *ngIf="showDocumentPreview"
  [header]="documentPreviewSelected.documentName"
  [(visible)]="showDocumentPreview"
  [modal]="true"
  [style]="{ width: '60vw', height: '70vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <iframe
    allowfullscreen
    [src]="documentPreviewSelected.base64"
    [title]="documentPreviewSelected.documentName"
    class="responsive-iframe"
  ></iframe>
</p-dialog>

<div *ngIf="showLegalizeForm">
  <p-dialog [header]="legalizationModalHeader" [modal]="true" [(visible)]="showLegalizeForm" [style]="{width: '60vw', height:'70vh'}" (onHide)="onCloseLegalizeForm()">
    <app-form-legalization [membership]="legalizeMembershipSelected" (transactionLegalized)="transactionLegalized()" (closeEditDialog)="onCloseLegalizeForm()"></app-form-legalization>
  </p-dialog>
</div>

  