import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { TimesCircleIcon } from 'primeng/icons/timescircle';

/**
 * Chip represents people using icons, labels and images.
 * @group Components
 */
function Chip_div_0_img_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "img", 6);
    i0.ɵɵlistener("error", function Chip_div_0_img_2_Template_img_error_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r6.imageError($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r1.image, i0.ɵɵsanitizeUrl);
  }
}
function Chip_div_0_ng_template_3_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r8.icon);
    i0.ɵɵproperty("ngClass", "p-chip-icon");
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function Chip_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chip_div_0_ng_template_3_span_0_Template, 1, 4, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r2.icon);
  }
}
function Chip_div_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "label");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r4.label);
  }
}
function Chip_div_0_ng_container_6_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_ng_container_1_span_1_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r13.close($event));
    })("keydown", function Chip_div_0_ng_container_6_ng_container_1_span_1_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r14);
      const ctx_r15 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r15.onKeydown($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap(ctx_r11.removeIcon);
    i0.ɵɵproperty("ngClass", "pi-chip-remove-icon");
    i0.ɵɵattribute("data-pc-section", "removeicon");
  }
}
function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesCircleIcon", 14);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template_TimesCircleIcon_click_0_listener($event) {
      i0.ɵɵrestoreView(_r17);
      const ctx_r16 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r16.close($event));
    })("keydown", function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template_TimesCircleIcon_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r17);
      const ctx_r18 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r18.onKeydown($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵclassMap("pi-chip-remove-icon");
    i0.ɵɵattribute("data-pc-section", "removeicon");
  }
}
function Chip_div_0_ng_container_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_ng_container_1_span_1_Template, 1, 4, "span", 11)(2, Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template, 1, 3, "TimesCircleIcon", 12);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r9.removeIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r9.removeIcon);
  }
}
function Chip_div_0_ng_container_6_span_2_1_ng_template_0_Template(rf, ctx) {}
function Chip_div_0_ng_container_6_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chip_div_0_ng_container_6_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Chip_div_0_ng_container_6_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 15);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_span_2_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r21 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r21.close($event));
    })("keydown", function Chip_div_0_ng_container_6_span_2_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r23 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r23.onKeydown($event));
    });
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_span_2_1_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵattribute("data-pc-section", "removeicon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r10.removeIconTemplate);
  }
}
function Chip_div_0_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_ng_container_1_Template, 3, 2, "ng-container", 5)(2, Chip_div_0_ng_container_6_span_2_Template, 2, 2, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r5.removeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r5.removeIconTemplate);
  }
}
function Chip_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, Chip_div_0_img_2_Template, 1, 1, "img", 2)(3, Chip_div_0_ng_template_3_Template, 1, 1, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor)(5, Chip_div_0_div_5_Template, 2, 2, "div", 4)(6, Chip_div_0_ng_container_6_Template, 3, 2, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r3 = i0.ɵɵreference(4);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.styleClass);
    i0.ɵɵproperty("ngClass", ctx_r0.containerClass())("ngStyle", ctx_r0.style);
    i0.ɵɵattribute("data-pc-name", "chip")("aria-label", ctx_r0.label)("data-pc-section", "root");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.image)("ngIfElse", _r3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.removable);
  }
}
const _c0 = ["*"];
class Chip {
  /**
   * Defines the text to display.
   * @group Props
   */
  label;
  /**
   * Defines the icon to display.
   * @group Props
   */
  icon;
  /**
   * Defines the image to display.
   * @group Props
   */
  image;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Whether to display a remove icon.
   * @group Props
   */
  removable = false;
  /**
   * Icon of the remove element.
   * @group Props
   */
  removeIcon;
  /**
   * Callback to invoke when a chip is removed.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  onRemove = new EventEmitter();
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  visible = true;
  removeIconTemplate;
  templates;
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'removeicon':
          this.removeIconTemplate = item.template;
          break;
        default:
          this.removeIconTemplate = item.template;
          break;
      }
    });
  }
  containerClass() {
    return {
      'p-chip p-component': true,
      'p-chip-image': this.image != null
    };
  }
  close(event) {
    this.visible = false;
    this.onRemove.emit(event);
  }
  onKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Backspace') {
      this.close(event);
    }
  }
  imageError(event) {
    this.onImageError.emit(event);
  }
  static ɵfac = function Chip_Factory(t) {
    return new (t || Chip)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Chip,
    selectors: [["p-chip"]],
    contentQueries: function Chip_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      label: "label",
      icon: "icon",
      image: "image",
      style: "style",
      styleClass: "styleClass",
      removable: "removable",
      removeIcon: "removeIcon"
    },
    outputs: {
      onRemove: "onRemove",
      onImageError: "onImageError"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 1,
    consts: [[3, "ngClass", "class", "ngStyle", 4, "ngIf"], [3, "ngClass", "ngStyle"], [3, "src", "error", 4, "ngIf", "ngIfElse"], ["iconTemplate", ""], ["class", "p-chip-text", 4, "ngIf"], [4, "ngIf"], [3, "src", "error"], [3, "class", "ngClass", 4, "ngIf"], [3, "ngClass"], [1, "p-chip-text"], ["tabindex", "0", "class", "pi-chip-remove-icon", 3, "click", "keydown", 4, "ngIf"], ["tabindex", "0", 3, "class", "ngClass", "click", "keydown", 4, "ngIf"], ["tabindex", "0", 3, "class", "click", "keydown", 4, "ngIf"], ["tabindex", "0", 3, "ngClass", "click", "keydown"], ["tabindex", "0", 3, "click", "keydown"], ["tabindex", "0", 1, "pi-chip-remove-icon", 3, "click", "keydown"], [4, "ngTemplateOutlet"]],
    template: function Chip_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, Chip_div_0_Template, 7, 11, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, TimesCircleIcon],
    styles: ["@layer primeng{.p-chip{display:inline-flex;align-items:center}.p-chip-text,.p-chip-icon.pi,.pi-chip-remove-icon.pi{line-height:1.5}.pi-chip-remove-icon{cursor:pointer}.p-chip img{border-radius:50%}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Chip, [{
    type: Component,
    args: [{
      selector: 'p-chip',
      template: `
        <div [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="style" *ngIf="visible" [attr.data-pc-name]="'chip'" [attr.aria-label]="label" [attr.data-pc-section]="'root'">
            <ng-content></ng-content>
            <img [src]="image" *ngIf="image; else iconTemplate" (error)="imageError($event)" />
            <ng-template #iconTemplate><span *ngIf="icon" [class]="icon" [ngClass]="'p-chip-icon'" [attr.data-pc-section]="'icon'"></span></ng-template>
            <div class="p-chip-text" *ngIf="label" [attr.data-pc-section]="'label'">{{ label }}</div>
            <ng-container *ngIf="removable">
                <ng-container *ngIf="!removeIconTemplate">
                    <span tabindex="0" *ngIf="removeIcon" [class]="removeIcon" [ngClass]="'pi-chip-remove-icon'" [attr.data-pc-section]="'removeicon'" (click)="close($event)" (keydown)="onKeydown($event)"></span>
                    <TimesCircleIcon tabindex="0" *ngIf="!removeIcon" [class]="'pi-chip-remove-icon'" [attr.data-pc-section]="'removeicon'" (click)="close($event)" (keydown)="onKeydown($event)" />
                </ng-container>
                <span *ngIf="removeIconTemplate" tabindex="0" [attr.data-pc-section]="'removeicon'" class="pi-chip-remove-icon" (click)="close($event)" (keydown)="onKeydown($event)">
                    <ng-template *ngTemplateOutlet="removeIconTemplate"></ng-template>
                </span>
            </ng-container>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-chip{display:inline-flex;align-items:center}.p-chip-text,.p-chip-icon.pi,.pi-chip-remove-icon.pi{line-height:1.5}.pi-chip-remove-icon{cursor:pointer}.p-chip img{border-radius:50%}}\n"]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    removable: [{
      type: Input
    }],
    removeIcon: [{
      type: Input
    }],
    onRemove: [{
      type: Output
    }],
    onImageError: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ChipModule {
  static ɵfac = function ChipModule_Factory(t) {
    return new (t || ChipModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ChipModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, TimesCircleIcon, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TimesCircleIcon, SharedModule],
      exports: [Chip, SharedModule],
      declarations: [Chip]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Chip, ChipModule };
