import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { Application } from '../../../../model/Application';
import { Course } from '../../../../model/Course';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Level } from '../../../../model/Level';
import { ProcessStatus } from '../../../../model/ProcessStage';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Discount } from '../../../../model/administrator/AgreementsAndDiscounts/Discount';
import { Product } from '../../../../model/administrator/ProductAndServices/Product';
import { Documento } from '../../../../model/documento';
import { AdministrationService } from '../../../../services/administration.service';
import { ApplicationService } from '../../../../services/application.service';
import { DocumentsService } from '../../../../services/documents.service';
import { LoadingService } from '../../../../services/loading.service';
@Component({
  selector: 'app-solicitud-review',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    DropdownModule,
    StepsModule,
    TabMenuModule,
    CommonModule,
    CardModule,
    ButtonModule,
    TabViewModule,
    DialogModule,
    SelectButtonModule,
    FormsModule,
    CascadeSelectModule,
    ToastModule,
    InputTextareaModule,
    TagModule,
    MultiSelectModule
    
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: './solicitud-review.component.html',
  styleUrl: './solicitud-review.component.scss',
})
export class SolicitudReviewComponent implements OnInit {
  activeIndex: number = 0;
  documentPreviewSelected: any = null;
  showDocumentPreview: boolean = false;
  @Input()
  application!: Application;

  @Output()
  goToBackEvent: EventEmitter<boolean> = new EventEmitter();

  stateOptions: any[] = [
    { label: 'Aprobar Solicitud', value: ProcessStatus.APPROVED },
    { label: 'Rechazar Solicitud', value: ProcessStatus.REJECTED },
  ];
  confirmationResponse: ProcessStatus = ProcessStatus.APPROVED;
  cursoAsignado?: Course;
  nivelAsignado?: Level;
  productosAsignados: Product[] = [];
  descuentos:Discount[] = [];
  courses: Course[] = [];
  levels: Level[] = [];
  rejectComments:string = '';
  constructor(
    private documentsService: DocumentsService,
    private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private applicationService: ApplicationService,
    private administrationService:AdministrationService
  ) {}

  ngOnInit(): void {

    this.buildMasterOptions();
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  showPreview(documento: Documento) {
    this.loadingService.showLoading();
    this.documentsService
      .getDocument(documento.documentId)
      .subscribe((res: HttpResponse) => {
        this.loadingService.hideLoading();
        this.documentPreviewSelected = {
          documentName:
            documento.id === 'docId'
              ? 'Documento de Identificación'
              : documento.id === 'docEps'
              ? 'Certificado de Eps'
              : documento.id === 'docDiscalimer'
              ? 'Consentimiento Informado'
              : documento.id,
          base64: this.sanitizer.bypassSecurityTrustResourceUrl(
            res.response.filestream
          ),
        };
        this.showDocumentPreview = true;
      });
  }

  
  async buildMasterOptions() {
    this.courses = await this.getCourses();
  }

  async getCourses(): Promise<Course[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      true,
      'isActive'
    );
    return groupData as Course[];
  }

  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }





  sendResponse(event: Event) {
    if (!this.confirmationResponse || (!this.confirmationResponse && !this.cursoAsignado)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atención',
        detail: 'Debe completar todos los campos requeridos para continuar',
      });
    } else {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `Esta seguro de enviar la respuesta de la solicitud con estado ${
          this.confirmationResponse === ProcessStatus.APPROVED
            ? 'Aprobada'
            : this.confirmationResponse === ProcessStatus.REJECTED
            ? 'Rechazada'
            : this.confirmationResponse
        }`,
        header: 'Confirmar envío de respuesta',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'Si',
        rejectLabel:'No',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          if (this.application && this.application.process) {
            this.application.process.processStage = this.confirmationResponse;

            if( this.confirmationResponse === ProcessStatus.APPROVED){
              this.application.assignedCourse = this.cursoAsignado?.spId;
              this.cursoAsignado!.associateProducts.newRegister = this.productosAsignados;
              this.application.assignedCourseDetail = this.cursoAsignado;
              this.application.assignedLevelDetail = this.nivelAsignado;
            }else if(this.confirmationResponse === ProcessStatus.REJECTED){
              this.application.comments = this.rejectComments
            }
          }
          this.loadingService.showLoading();
          this.applicationService
          .updateApplication(this.application)
          .subscribe((updateResponse: HttpResponse) => {
            if (updateResponse.response) {
              this.messageService.add({
                severity: 'success',
                summary: 'Envío Exitoso!',
                detail: 'La solicitud se respondió correctamente',
              });
              this.loadingService.hideLoading();
              this.goToBack();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error!',
                detail:
                  'Ocurrió un error actualizando el estado de la solicitud, por favor inténtelo nuevamente',
                life: 3000,
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'warning',
            summary: 'Sin Cambios',
            detail: 'No se realizaron cambios en la solicitud',
            life: 3000,
          });
        },
      });
    }
  }

  goToBack() {
    this.goToBackEvent.emit(true);
  }
}
