<div class="card">
  <div class="row">
    <div class="mb-3 text-left font-medium text-md">
      <span class="text-primary-700">
        <p-button
          icon="pi pi-arrow-left"
          [rounded]="true"
          [outlined]="true"
          severity="primary"
          [styleClass]="'back-button'"
          (click)="goToBack()"
        ></p-button>
        Revisión de Solicitud
      </span>
      <span class="text-900">
        | {{ application.applicant.name }} {{ application.applicant.lastName }},
        {{ calculateYearsOld(application.applicant.birthDay) }} Años</span
      >
    </div>
    <div class="flex mb-2 gap-2 justify-content-end">
      <p-button
        (click)="activeIndex = 0"
        [rounded]="true"
        styleClass="w-2rem h-2rem p-0"
        [outlined]="activeIndex !== 0"
        label="1"
      ></p-button>
      <p-button
        (click)="activeIndex = 1"
        [rounded]="true"
        styleClass="w-2rem h-2rem p-0"
        [outlined]="activeIndex !== 1"
        label="2"
      ></p-button>
      <p-button
        (click)="activeIndex = 2"
        [rounded]="true"
        styleClass="w-2rem h-2rem p-0"
        [outlined]="activeIndex !== 2"
        label="3"
      ></p-button>
    </div>
  </div>

  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-user"></i>
        <span class="ml-1"> Información Personal</span>
      </ng-template>

      <div class="surface-section">
        <ul class="list-none p-0 m-0">
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">¿Tiene Convenio?</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <p-tag
                *ngIf="application.convenio && application.convenio !== 'NA'"
                severity="primary"
                [rounded]="true"
                [value]="application.convenio"
              />
              <div
                *ngIf="!application.convenio || application.convenio === 'NA'"
              >
                {{ application.convenio || "Sin Convenio" }}
              </div>
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              ¿Tiene Experiencia?
            </div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <p-tag
                *ngIf="
                  application.experienceLevel &&
                  application.experienceLevel !==
                    'Sin experiencia previa en patinaje'
                "
                severity="info"
                [rounded]="true"
                [value]="application.experienceLevel"
              />
              <div
                *ngIf="
                  !application.experienceLevel ||
                  application.experienceLevel ===
                    'Sin experiencia previa en patinaje'
                "
              >
                {{
                  application.experienceLevel ||
                    "Sin experiencia previa en patinaje"
                }}
              </div>
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Nombre</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.name }}
              {{ application.applicant.lastName }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Identificación</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.idType }}
              {{ application.applicant.idNumber }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Género</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{
                application.applicant.gender === "M"
                  ? "Masculino"
                  : application.applicant.gender === "F"
                  ? "Femenino"
                  : application.applicant.gender === "NB"
                  ? "No Binario"
                  : application.applicant.gender
              }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              Fecha de Nacimiento
            </div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.birthDay }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Edad Actual</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ calculateYearsOld(application.applicant.birthDay) }} Años
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Teléfono</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.contactInformation.telephone }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Email</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.contactInformation.email }}
            </div>
          </li>
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
          >
            <div class="text-500 w-6 md:w-2 font-medium">EPS</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ application.applicant.assistanceInformation.insurance }}
            </div>
          </li>
        </ul>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-file"></i>
        <span class="ml-1">Documentos</span>
      </ng-template>
      <div class="surface-section">
        <ul class="list-none p-0 m-0">
          <li
            class="flex align-items-center py-2 px-1 border-bottom-1 surface-border flex-wrap"
            *ngFor="
              let document of application.documents.docmuentsToSend;
              index as i
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              Documento N° {{ i + 1 }}
            </div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{
                document.id === "docId"
                  ? "Documento de Identificación"
                  : document.id === "docEps"
                  ? "Certificado de Eps"
                  : document.id === "docDisclaimer"
                  ? "Consentimiento Informado"
                  : document.id === "docSponsor"
                  ? "Soporte Convenio"
                  : document.id
              }}
            </div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <button
                pButton
                pRipple
                label="Ver"
                icon="pi pi-eye"
                class="p-button-text"
                (click)="showPreview(document)"
              ></button>
            </div>
          </li>
        </ul>
      </div>
    </p-tabPanel>
    <p-tabPanel
      *ngIf="
        application.process?.processStage === 'created' ||
        application.process?.processStage === 'queued'
      "
    >
      <ng-template pTemplate="header">
        <i class="pi pi-verified"></i>
        <span class="ml-1">Confirmación</span>
      </ng-template>
      <div class="card flex justify-content-center">
        <p-selectButton
          [options]="stateOptions"
          [(ngModel)]="confirmationResponse"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>

      <div *ngIf="confirmationResponse === 'approved'">
        <p-card
          header="Asignación de Curso"
          class="mt-2"
          subheader="Selecciona los valores correspondientes al curso inicial asignado y ciclo de matricula disponibles para que el solicitante continue con la matricula"
        >
          <div class="row">
            <span class="p-float-label inline-block m-2">
              <p-dropdown
                [options]="courses"
                [(ngModel)]="cursoAsignado"
                placeholder="Selecciona un curso"
                optionLabel="name"
                inputId="float-label"
              ></p-dropdown>
              <label for="float-label" class="mr-2">Curso* </label>
            </span>
            <span class="p-float-label inline-block m-2">
              <p-dropdown
                [options]="cursoAsignado?.levels || []"
                [(ngModel)]="nivelAsignado"
                placeholder="Selecciona un curso"
                optionLabel="name"
                inputId="float-label"
              ></p-dropdown>
              <label for="float-label" class="mr-2">Nivel* </label>
            </span>
          </div>
        </p-card>
        <p-card
          header="Asignación de productos y servicios"
          class="mt-2"
          subheader="Selecciona los productos y/o servicios que debe pagar el usuario para continuar con la matricula"
        >
          <div class="row">
            <span class="p-float-label m-2">
              <p-multiSelect
                display="chip"
                [showClear]="true"
                [style]="{ display: 'flex' }"
                [options]="cursoAsignado?.associateProducts?.newRegister"
                [(ngModel)]="productosAsignados"
                optionLabel="name"
                inputId="assignedProductsSelect"
              ></p-multiSelect>
              <label for="">Productos y Servicios *</label>
            </span>
          </div>
          <div class="justify-content-center flex mt-4">
            <p-button
              (click)="sendResponse($event)"
              icon="pi pi-check"
              label="Enviar Respuesta"
              [disabled]="
                !cursoAsignado ||
                !nivelAsignado ||
                productosAsignados.length === 0
              "
            ></p-button>
          </div>
        </p-card>
        <p-toast [baseZIndex]="1200"></p-toast>
        <p-confirmDialog></p-confirmDialog>
      </div>

      <div *ngIf="confirmationResponse === 'rejected'">
        <p-card
          header="Comentarios"
          class="mt-2"
          subheader="Ingrese la descripción del motivo de rechazo de la solicitud"
        >
          <div class="row">
            <span class="p-float-label inline-block m-2">
              <textarea
                rows="5"
                cols="30"
                pInputTextarea
                [(ngModel)]="rejectComments"
              ></textarea>
              <label for="float-label" class="mr-2">Comentarios* </label>
            </span>
          </div>
          <div class="justify-content-center flex mt-2">
            <p-button
              (click)="sendResponse($event)"
              icon="pi pi-check"
              label="Enviar Respuesta"
            ></p-button>
          </div>

          {{ productosAsignados }}
        </p-card>

        <p-toast [baseZIndex]="1200"></p-toast>
        <p-confirmDialog></p-confirmDialog>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<p-dialog
  *ngIf="showDocumentPreview"
  [header]="documentPreviewSelected.documentName"
  [(visible)]="showDocumentPreview"
  [modal]="true"
  [style]="{ width: '60vw', height: '70vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <iframe
    allowfullscreen
    [src]="documentPreviewSelected.base64"
    [title]="documentPreviewSelected.documentName"
    class="responsive-iframe"
  ></iframe>
</p-dialog>
