<div *ngIf="showCourses">
  <p-table
    [value]="membershipsDatail"
    sortField="auditData.created_at"
    sortMode="multiple"
    [sortOrder]="1"
    [scrollable]="true"
    scrollHeight="400px"
    rowGroupMode="subheader"
    dataKey="course.name"
    groupRowsBy="course.name"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>Cursos</th>
        <th pSortableColumn="level.levelOrder">Nivel</th>
        <th>Ciclo</th>
        <th>Sede</th>
        <th>Horario</th>
        <th>Estado</th>
        <th pSortableColumn="auditData.created_at">Fecha Matricula</th>
        <th>Acciones</th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="groupheader"
      let-membership
      let-rowIndex="rowIndex"
      let-expanded="true"
    >
      <tr pRowGroupHeader>
        <td colspan="5">
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="membership"
            class="p-button-text p-button-rounded p-button-plain mr-2"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button>
          <span class="font-bold ml-2">{{ membership.course.name }}</span>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="groupfooter" let-membership>
            <tr>
                <td colspan="5" class="text-right font-bold pr-6">{{membership.member.name + '' + membership.member.lastName}}</td>
            </tr>
        </ng-template> -->
    <ng-template
      pTemplate="rowexpansion"
      let-membership
      let-rowIndex="rowIndex"
      let-columns="columns"
    >
      <tr>
        <td>
          <span class="p-column-title font-bold">Curso</span>
          {{ membership.course.name }}
        </td>
        <td>
          <span class="p-column-title font-bold">Nivel</span>
          <span class="ml-1 vertical-align-middle">{{
            membership.level.name
          }}</span>
        </td>
        <td>
          <span class="p-column-title font-bold">Ciclo</span>
          <span class="ml-1 vertical-align-middle">{{
            membership.season.name
          }}</span>
        </td>
        <td>
          <span class="p-column-title font-bold">Sede</span>
          <span class="ml-1 vertical-align-middle">{{
            membership.headquarter.name
          }}</span>
        </td>
        <td>
          <span class="p-column-title font-bold">Horario</span>
          <span class="ml-1 vertical-align-middle"
            >{{ membership.classGroup.sch.days }}
            {{ membership.classGroup.sch.startTime }} -
            {{ membership.classGroup.sch.endTime }}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Estado</span>
          <p-tag
            [value]="getStatus(membership.academyState)"
            [severity]="getSeverity(membership.academyState)"
          ></p-tag>
        </td>
        <td>
          <span class="p-column-title font-bold">Fecha Matricula</span>
          <span class="ml-1 vertical-align-middle">{{
            membership.auditData.created_at
          }}</span>
        </td>
        <td>
          <span class="p-column-title font-bold">Acciones</span>
          <p-button
            *ngIf="
              membership.academyState.state === 'pending' ||
              membership.academyState.state === 'InProgress' ||
              membership.academyState.state === 'approved' ||
              membership.academyState.state === 'failed'
            "
            icon="pi pi-pencil"
            [rounded]="true"
            [text]="true"
            pTooltip="Editar Matricula"
            placeholder="autoHide: true"
            tooltipPosition="top"
            (click)="initEdit(membership)"
          ></p-button>

          <p-button
            icon="pi pi-file-pdf"
            [rounded]="true"
            [text]="true"
            pTooltip="Ver Comprobante de Transacción"
            tooltipPosition="top"
            placeholder="autoHide: true"
            (click)="showPreview(membership)"
          ></p-button>

          <p-button
            icon="pi pi-id-card"
            [rounded]="true"
            [text]="true"
            pTooltip="Ver Carnet Digital"
            tooltipPosition="top"
            placeholder="autoHide: true"
            (click)="showDigitalLicense(membership)"
          ></p-button>

          <p-button
            icon="pi pi-send"
            [rounded]="true"
            [text]="true"
            pTooltip="Reenviar Carnet Digital"
            tooltipPosition="top"
            placeholder="autoHide: true"
            (click)="resendInformation(membership)"
          ></p-button>

          <p-button
            icon="pi pi-ban"
            [rounded]="true"
            [text]="true"
            pTooltip="Anular Matricula"
            tooltipPosition="top"
            placeholder="autoHide: true"
            (click)="onCancellation(membership)"
          ></p-button>
          <!-- 


        <p-button
        icon="pi pi-print"
        [rounded]="true"
        [text]="true"
        pTooltip="Generar Comprobante"
        tooltipPosition="top" 
        placeholder="autoHide: true"
        (click)="initEdit(membership)"
      ></p-button> -->
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="showEditMembership">
  <app-member-edit-membership
    [member]="member"
    [membership]="membershipSelected"
    (closeEditDialog)="closeEditDialog()"
    (refreshMeberships)="updateCourseHistory()"
  ></app-member-edit-membership>
</div>
<p-dialog
  *ngIf="showDocumentPreview"
  [header]="documentPreviewSelected.documentName"
  [(visible)]="showDocumentPreview"
  [modal]="true"
  [style]="{ width: '60vw', height: '70vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <iframe
    allowfullscreen
    [src]="documentPreviewSelected.base64"
    [title]="documentPreviewSelected.documentName"
    class="responsive-iframe"
  ></iframe>
</p-dialog>

<!-- <p-confirmDialog
  [style]="{ width: '450px', 'z-index': '2000 !important' }"
  [autoZIndex]="false"
></p-confirmDialog> -->

<p-toast></p-toast>

<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div
      class="flex flex-column align-items-center p-5 surface-overlay border-round"
    >
      <div
        class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
      >
        <i [class]="message.icon"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
        {{ message.header }}
      </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button
          pButton
          label="Aceptar"
          (click)="cd.accept()"
          class="w-8rem"
        ></button>
        <button
          pButton
          label="Cancelar"
          (click)="cd.reject()"
          class="p-button-outlined w-8rem"
        ></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<p-dialog
  header="Anulación de Matricula"
  [modal]="true"
  [(visible)]="showCancellationDialog"
  [style]="{ width: '25rem' }"
>
  <span class="p-text-secondary block mb-5"
    >Explique el motivo para la anulación de la matricula</span
  >
  <div class="flex align-items-center gap-2 mb-2">
    <textarea
      rows="5"
      cols="40"
      pInputTextarea
      [(ngModel)]="cancellationReason"
    >
    </textarea>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button
      label="Cancelar"
      severity="secondary"
      (onClick)="showCancellationDialog = false"
    />
    <p-button label="Guardar" (onClick)="onAcceptCancellation()" [disabled]="cancellationReason.length <= 0"/>
  </div>
</p-dialog>
