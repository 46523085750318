import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { CrudTable } from '../../../model/CrudTable';
import { HttpResponse } from '../../../model/HttpResponse';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
import { GroupsSeasonsComponent } from './groups-seasons/groups-seasons.component';

@Component({
  selector: 'app-ciclos',
  standalone: true,
  templateUrl: './ciclos.component.html',
  styleUrl: './ciclos.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    MultiSelectModule,
    FormsModule,
    GroupsSeasonsComponent,
  ],
})
export class CiclosComponent implements OnInit {
  seasons: Season[] = [];
  seasonSelected!: Season;
  loading: boolean = false;
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  showSeasons: boolean = false;
  showSeasonGroups: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private gestionService: GestionService,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.selectSeasons();
  }

  async selectSeasons() {
    this.loadingService.showLoading();
    this.loading = true;
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'availableToAdministrativeGestion',
              parameterName: '@Id',
              parameterValue: true,
            }
          ],
        },
      },
      type: AcademyAdministratorType.SEASONS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if (res.response.length > 0) {
          this.seasons = res.response;
          this.showSeasons = true;
        }
        this.loading = false;
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Nombre',
          sorted: true,
          sortItem: 'name',
        },
        {
          label: 'Comentarios',
          sorted: true,
          sortItem: 'comments',
        },
        {
          label: 'Fecha Inicio',
          sorted: true,
          sortItem: 'startDate',
        },
        {
          label: 'Fecha Fin',
          sorted: true,
          sortItem: 'endDate',
        },
        {
          label: 'Disponible Matricula',
          sorted: true,
          sortItem: 'availableToRegister',
        },
        {
          label: 'Disponible Instructores',
          sorted: true,
          sortItem: 'availableToAdministrativeGestion',
        },
        //   {
        //     label: 'Sedes',
        //     sorted: false,
        //     sortItem: null,
        //   },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: [
        'name',
        'comments',
        'startDate',
        'endDate',
        'availableToRegister',
        'availableToAdministrativeGestion',
      ],
    };
  }

  showGroups(season: Season) {
    this.seasonSelected = season;
    this.showSeasons = false;
    this.showSeasonGroups = true;
  }

  returnAllSeasons() {
    this.showSeasons = true;
    this.showSeasonGroups = false;
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Season) {
    this.getFormRenewal(item);
  }

  deleteItem(item: Season) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.DELETE,
          payload: item,
          type: AcademyAdministratorType.SEASONS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectSeasons();
            this.loadingService.hideLoading();
          });
      },
    });
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      comments: [null],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      headquarters: [null],
      availableToRegister: [true, [Validators.required]],
      availableToAdministrativeGestion: [true, [Validators.required]],
      seasonId: [null],
    });
    this.dialogHeader = 'Nuevo Ciclo';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Season) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      name: [item.name, [Validators.required]],
      comments: [item.comments, [Validators.required]],
      startDate: [item.startDate, [Validators.required]],
      endDate: [item.endDate, [Validators.required]],
      headquarters: [item.headquarters, [Validators.required]],
      availableToRegister: [item.availableToRegister, [Validators.required]],
      availableToAdministrativeGestion: [
        item.availableToAdministrativeGestion,
        [Validators.required],
      ],
      seasonId: [item.seasonId || ''],
    });
    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  clear(table: Table) {
    table.clear();
  }
}
