import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { v4 as uuidv4 } from 'uuid';
import { ClassGroup } from '../../../model/ClassGroup';
import { Course } from '../../../model/Course';
import { CrudTable } from '../../../model/CrudTable';
import { Dependence } from '../../../model/Dependencie';
import { HttpResponse } from '../../../model/HttpResponse';
import { Level } from '../../../model/Level';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { Product } from '../../../model/administrator/ProductAndServices/Product';
import { DiscountType } from '../../../model/enum/DiscountType';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { ValidationService } from '../../../services/validation.service';
import { DependenciesListComponent } from "../../shared/dependencies-list/dependencies-list.component";
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
    selector: 'app-cursos-niveles',
    standalone: true,
    templateUrl: './cursos-niveles.component.html',
    styleUrl: './cursos-niveles.component.scss',
    providers: [MessageService, ConfirmationService],
    imports: [
        MessagesModule,
        ToastModule,
        InputNumberModule,
        MenubarModule,
        ButtonModule,
        OrderListModule,
        TimelineModule,
        InputTextareaModule,
        CalendarModule,
        InputMaskModule,
        SelectButtonModule,
        InputSwitchModule,
        InputTextModule,
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        CommonModule,
        DialogModule,
        ReactiveFormsModule,
        MultiSelectModule,
        DependenciesListComponent
    ]
})
export class CursosNivelesComponent implements OnInit {
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  levelValidateForm!: FormGroup;
  showForm: boolean = false;
  showLevelForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  isNewLevel: boolean = false;
  isEditLevel: boolean = false;
  productAndServices:Product[] = [];
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];

  productTypes: any[] = [
    { label: 'CONVENIO', value: DiscountType.AGREEMENT },
    { label: 'DESCUENTO', value: DiscountType.DISCOUNT },
  ];

  selectedCourse: Course | any = {};
  items: MenuItem[] | undefined;
  defaultLevaleValue: Level = {
    objective: '',
    clases: 0,
    isActive: true,
    isOptional: false,
    levelId: '',
    levelOrder: 0,
    name: '',
  };
  leveToAdd: Level = this.defaultLevaleValue;
  showDependencies: boolean = false;
  dependenciesToDelete: Dependence[] = [];
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validationServices: ValidationService
  ) {}
  ngOnInit(): void {
    this.selectCoursesAndLevels();
    this.selectProductAndServices();
    this.items = [
      {
        label: 'Agregar Nivel',
        icon: 'pi pi-fw pi-plus',
      },
    ];
  }

  async selectCoursesAndLevels() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.COURSES_LEVELS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }


  async selectProductAndServices() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.PRODUCTS_SERVICES,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if(res.response.length > 0){
          this.productAndServices = res.response;
        }
        this.loadingService.hideLoading();
      });
  }

  reorderLevels() {
    this.validateForm
      .get('levels')
      ?.value.forEach((level: Level, index: number) => {
        level.levelOrder = index + 1;
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Nombre',
          sorted: true,
          sortItem: 'name',
        },
        {
          label: 'Descripción',
          sorted: true,
          sortItem: 'description',
        },
        {
          label: 'Activo',
          sorted: true,
          sortItem: 'isActive',
        },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: ['name', 'description', 'isActive'],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Course) {
    this.getFormRenewal(item);
  }

  cancelEditItem() {
    this.showForm = false;
    this.selectCoursesAndLevels();
  }

  async deleteItem(item: Course) {
    this.dependenciesToDelete = [];
    this.showDependencies = false;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: item,
      type: AcademyAdministratorType.COURSES_LEVELS,
    };
    let dependencies = await this.validationServices.dependenciesToDelete(
      entity
    );
    if (dependencies.length === 0) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
       
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectCoursesAndLevels();
            this.loadingService.hideLoading();
          });
      },
    });
  } else {
    this.dependenciesToDelete = dependencies.map(
      (dependencie: ClassGroup) => {
        const dependenciToList: Dependence = {
          id: dependencie.id,
          type: AcademyAdministratorType.GROUPS,
          name: dependencie.classGroupId,
        };
        return dependenciToList;
      }
    );
    this.showDependencies = true;
  }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      isActive: [true, [Validators.required]],
      levels: [[], [Validators.required]],
      associatedProductsNew:[[], [Validators.required]],
      associatedProductsRenewal:[[], [Validators.required]]
    });
    this.dialogHeader = 'Nuevo Curso';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.reorderLevels();
    this.showForm = true;
  }

  getFormRenewal(item: Course) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      name: [item.name, [Validators.required]],
      description: [item.description, [Validators.required]],
      isActive: [item.isActive, [Validators.required]],
      levels: [item.levels, [Validators.required]],
      associatedProductsNew:[this.getAssociatedProduts(item.associateProducts?.newRegister) || [], [Validators.required]],
      associatedProductsRenewal:[this.getAssociatedProduts(item.associateProducts?.renewal) || [], [Validators.required]],
      coursesLevelId: [item.coursesLevelId],
    });

    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.reorderLevels();
    this.showForm = true;
  }

  getAssociatedProduts(products:Product[]):Product[]{
    let productsInUpdater:Product[] =  [];
    this.productAndServices.forEach((baseProduct:Product) => {
        if(products.some((product:Product) => product.productServiceId === baseProduct.productServiceId)){
          productsInUpdater.push(baseProduct);
        }
    });

    return productsInUpdater;

  }

  closeDialog() {
    this.showForm = false;
  }
  closeLevelialog() {
    this.showLevelForm = false;
  }
  closeDependenceDialog() {
    this.showDependencies = false;
  }


  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.COURSES_LEVELS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectCoursesAndLevels();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.COURSES_LEVELS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectCoursesAndLevels();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Course {
    let item: Course = {
      name: form['name'].toUpperCase(),
      coursesLevelId: form['coursesLevelId'] || uuidv4(),
      levels: form['levels'],
      description: form['description'],
      isActive: form['isActive'],
      associateProducts:{
        newRegister:form['associatedProductsNew'],
        renewal: form['associatedProductsRenewal']
      } 
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }

  initAddLevel() {
    this.levelValidateForm = this.fb.group({
      name: [null, [Validators.required]],
      clases: [null, []],
      objective: [null, [Validators.required]],
      isActive: [true, [Validators.required]]
    });
    this.isNewLevel = true;
    this.isEditLevel = false;
    this.showLevelForm = true;
  }

  addLevel() {
    if (this.levelValidateForm.valid) {
      this.showForm = false;

      const level: Level = {
        id: uuidv4(),
        name: this.levelValidateForm.value['name'],
        clases: 0,
        levelId: uuidv4(),
        objective: this.levelValidateForm.value['objective'],
        isActive: this.levelValidateForm.value['isActive'],
        isOptional: false,
        levelOrder: (this.validateForm.get('levels')?.value.lenght + 1) | 0,
      };

      const levels = this.validateForm.get('levels')?.value.concat(level);

      this.validateForm.get('levels')?.setValue(levels);
      this.showLevelForm = false;
      this.reorderLevels();
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
        key: 'bc',
      });
    }
    this.showForm = true;
  }

  initEditlevel(level: Level) {
    this.levelValidateForm = this.fb.group({
      name: [level.name, [Validators.required]],
      objective: [level.objective, [Validators.required]],
      isActive: [level.isActive, [Validators.required]],
      isOptional: [level.isOptional, [Validators.required]],
      id: [level.id],
    });
    this.isNewLevel = false;
    this.isEditLevel = true;
    this.showLevelForm = true;
  }

  editLevel() {
    if (this.levelValidateForm.valid) {
      this.loadingService.showLoading();
      const level: Level = {
        id: this.levelValidateForm.value['id'],
        name: this.levelValidateForm.value['name'].toUpperCase(),
        clases: 0,
        levelId: this.levelValidateForm.value['levelId'],
        objective: this.levelValidateForm.value['objective'],
        isActive: this.levelValidateForm.value['isActive'],
        isOptional: this.levelValidateForm.value['isOptional'],
        levelOrder: this.levelValidateForm.value['levelOrder'],
      };

      let levels: Level[] = this.validateForm
        .get('levels')
        ?.value.map((levelOnList: Level) => {
          if (levelOnList.id === level.id) {
            levelOnList = level;
          }
          return levelOnList;
        });

      this.validateForm.get('levels')?.setValue(levels);

      this.showLevelForm = false;
      this.reorderLevels();
    } else {
    }
    this.loadingService.hideLoading();
  }

  initDeleteLevel(level: Level) {
    this.confirmationService.confirm({
      message: `Estas seguro de borrar el nivel ${level.name} ?`,
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let levels: Level[] = this.validateForm
          .get('levels')
          ?.value.filter((levelFind: Level) => levelFind.id !== level.id);
          
          this.validateForm.get('levels')?.setValue(levels);
          this.showLevelForm = false;
          this.reorderLevels();
      },
    });
  }
}
